<section class="plans">
  <h2 class="text-dark font-weight-bold mb-3">Plans</h2>

  <table class="table table-bordered table-hover mb-5 table-fixed">
    <thead class="text-primary">
      <tr>
        <th class="border-0"></th>
        <th class="border-0 text-center">Discovery</th>
        <th class="border-bottom-0 text-center">Proposal</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Create an account</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
  
      <tr>
        <td>Manage Product Sheet</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
  
      <tr>
        <td>Manage Manufacturer description</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
  
      <tr>
        <td>Publish Product</td>
        <td class="text-center">--</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
  
      <tr>
        <td>Access APIs</td>
        <td class="text-center">--</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
      <tr>
        <td>Bulk load Excel</td>
        <td class="text-center">--</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
  
      <tr>
        <td>Manage users</td>
        <td class="text-center">--</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
  
      <tr>
        <td>Manage Account (contract, Invoices ....)</td>
        <td class="text-center">--</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
      </tr>
      <tr>
        <th class="border-0"></th>
        <th class="border-0 text-center">Free</th>
        <th class="border-bottom-0 text-center text-nowrap">250$ / month</th>
      </tr>
    </tbody>
  </table>
  <table class="table table-bordered table-hover table-fixed">
    <tbody>
      <tr>
        <td>Your current plan</td>
        <td class="text-center">
          <img src="assets/images/check.svg" alt="" />
        </td>
        <td class="text-center">--</td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-end">
    <button class="btn btn-primary border-0 btn-lg">Upgrade</button>
  </div>
</section>
