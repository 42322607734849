import { ToastrService } from "ngx-toastr";
import { User } from './../../../models/user';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VALIDATION_REGEX, SUCCESS_MESSAGES } from 'src/app/common/constants';
import { ConfirmedValidator } from 'src/app/common/customValidators/passwordMatch';
import { resetPasswords } from 'src/app/services/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { getFromStorage } from 'src/app/common/utils';
import {TranslateService} from '@ngx-translate/core';
import { ManagerService } from 'src/app/services/manager.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public user: User
  public resetPasswordForm: FormGroup;
  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,           
              private managerService: ManagerService,
              private translate: TranslateService,) { }

  ngOnInit(): void {

    this.user = getFromStorage('user');
    this.resetPasswordForm = this.formBuilder.group(
      {
        oldPassword: [
          "",
          [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],
        ],
        newPassword: [
          "",
          [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],
        ],
        reenterPassword: ["", [Validators.required]],
      },
      {
        validator: ConfirmedValidator("newPassword", "reenterPassword"),
      }
    );

    this.managerService.switchLn.subscribe((ln: any) => {
      if(ln && ln.ln) this.translate.use(ln.ln);
    })
  }

  get formControl() {
    return this.resetPasswordForm.controls;
  }

  async resetPassword(){
    this.spinner.show();
    let { message, result }  = await resetPasswords(this.user, this.resetPasswordForm.value.oldPassword, this.resetPasswordForm.value.newPassword);
    this.spinner.hide();
    if (!result) {
      this.toastr.error(message);
      this.activeModal.close();
      return;
    }
    if(result && result === "SUCCESS")
        this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD);
    this.activeModal.close();
  }
}
