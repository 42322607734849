<div role="document">
    <div class="modal-content">
        <!-- modal header -->
        <div class="modal-header ">
            <h4 class="modal-title text-dark font-weight-bold ">{{'caracterstics' | translate}}</h4>
        </div>
        <!-- modal header -->
        <div class="modal-body">
            <!-- modal content -->
            <div *ngIf = "data && data.length > 0">
            <div class="table-responsive">
            <table class="table table-fixed table-bordered border-primary rounded">
                  <thead class="text-dark">
                    <tr>
                      <th scope="col" class="border border-primary">{{'select' | translate}}</th>
                      <th scope="col" class="border border-primary">{{'category' | translate}}</th>
                      <th scope="col" class="border border-primary">{{'description' | translate}}</th>
                    </tr>
                  </thead>
            
                  <tbody class="text-primary">
                    <tr *ngFor="let dt of data | slice: ((currentPage-1)*pageCount) : ((currentPage*pageCount)); let i = index">
                      <td class="border border-primary">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id={{i}} [(ngModel)]="dt.include" [checked]="dt.include">
                          <label class="custom-control-label" for={{i}}>
                            <span class="text-muted invisible">ss</span>
                        </label>
                      </div>
                      </td>
                      <td class="border border-primary">{{dt?.caracteristique}}</td>
                      <td class="border border-primary">{{dt?.description}}</td>
                    </tr>

                  
                    </tbody>
              </table>
              </div>

            <app-pagination [totalItems]="data.length" [pageSize]="pageCount" (pageClicked)="setPage($event)"
              *ngIf="data.length > pageCount">
            </app-pagination>
            </div>

            <div *ngIf = "!data || data.length === 0">
              {{'No' | translate}} {{'caracterstics' | translate}}
            </div>
            <!-- modal content -->

        </div>
        <div class="modal-footer d-flex">


            <button type="button" class="font-weight-bold  border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
               (click) = "activeModal.close({success: false})">{{'cancel' | translate}}</button>
            <button type="button" class="btn button_form  font-weight-bold text-white pl-5 pb-3 pr-5 pt-3" (click) = "saveChars()">{{'save' | translate}}</button>


        </div>
    </div>
</div>
