// core modules
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  EDITOR_CONFIG,
  ERROR_MESSAGES,
  MAX_FILE_SIZE,
  VALIDATION_REGEX,
} from "../../../common/constants";
import { getS3Url } from "../../../common/utils";
import { ProductService } from "../../../services/product.service";
import { TranslateService } from "@ngx-translate/core";

// project modules
import { Language } from "./../../../models/language";
import { Country } from "./../../../models/country";
import { ManagerService } from "src/app/services/manager.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
@Component({
  selector: "app-regional-data",
  templateUrl: "./regional-data.component.html",
  styleUrls: ["./regional-data.component.scss"],
})
export class RegionalDataComponent implements OnInit {
  @Input() public data;
  @Input() public index;
  public regionalData: FormGroup;
  public languages: Language[];
  public countries: Country[];
  public photoURL;
  public photoError: string;
  public photo: File;
  editorConfig: AngularEditorConfig = EDITOR_CONFIG;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private productService: ProductService,
    private managerService: ManagerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.regionalData = this.formBuilder.group({
      language_id: [null, Validators.required],
      country_id: [null, Validators.required],
      url: ["", Validators.pattern(VALIDATION_REGEX.WEBSITE)],
      description: [""],
      email: ["", Validators.email],
      contact_number: ["", Validators.pattern(VALIDATION_REGEX.CONTACT_NUMBER)],
      html_description: [""],
    });
    if (this.data) this.patchRegionalData();
    this.getLanguages();
    this.getCountries();

    this.managerService.switchLn.subscribe((ln: any) => {
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
  }

  patchRegionalData = () => {
    let valuesToPatch = {};
    for (let key of Object.keys(this.data)) {
      valuesToPatch[key] = this.data[key];
    }
    this.regionalData.patchValue(valuesToPatch);
    if (this.data.photo_url) this.photoURL = this.data.photo_url;
  };

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await this.productService.getMetaData("country");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await this.productService.getMetaData("language");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  get formControl() {
    return this.regionalData.controls;
  }

  selectImage = async (event, type) => {
    let {
      target: { files },
    } = event;
    let [file] = files;
    let [fileType] = file.type.split("/");

    if (type === "image" && fileType !== type) {
      event.srcElement.value = null;
      this.photoError = "Invalid File Type.";
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      event.srcElement.value = null;
      this.photoError = "File too Big! Please select a file less than 100mb";
      return;
    }

    this.photoError = null;
    this.photo = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (event) => {
      this.photoURL = reader.result;
    };
  };

  saveRegionalData = async () => {
    console.log(this.photo);
    let data = this.regionalData.value;
    data.country_id = parseInt(data.country_id);
    data.language_id = parseInt(data.language_id);
    if (this.photo) {
      data["photo_url"] = await this.getFileUrl();
    }
    let response = {
      success: true,
      data: data,
      index: this.index,
    };
    this.activeModal.close(response);
  };

  getFileUrl = async () => {
    let params = {
      file_name: this.photo.name,
      content_type: this.photo.type,
    };
    this.spinner.show();
    try {
      // get signed url from backend
      let { key, url } = (await this.productService.updateFile(params)) || {};
      // upload file to signed url
      await this.productService.uploadFile(url, this.photo);
      this.spinner.hide();
      // return s3 url
      return getS3Url(key);
    } catch (err) {
      this.spinner.hide();
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }

    return null;
  };
}
