<div role="document">
    <div class="modal-content">

        <!-- modal header -->
        <div class="modal-header bg-primary ">
            <h2 class="modal-title text-white"> {{'confirmation' | translate}} </h2>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button> -->
        </div>
        <!-- modal header -->
        <div class="modal-body">
            <!-- modal content -->
            <p translate="">{{message}}</p>
            <!-- modal content -->

        </div>
        <div class="modal-footer d-flex"
            [ngClass]="{'justify-content-between':showCancel, 'justify-content-center': !showCancel}">


            <button type="button" class="btn btn-primary" (click)="activeModal.close(false)"
                *ngIf="showCancel">{{'cancel' | translate}}</button>
            <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">{{'ok' | translate}}</button>


        </div>
    </div>
</div>