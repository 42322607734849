// core modules
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  VALIDATION_REGEX,
  ALLOWED_ROLE,
  ROLE_NAME,
  DEFAULT_LANGUAGE_TYPE,
  FRENCH_LANGUAGE,
} from "../../common/constants";
import { ConfirmedValidator } from "../../common/customValidators/passwordMatch";
// project modules
import { completeNewPassword, SignIn, ForgotPassword, ForgotPasswordSubmit, SignUp, ConfirmSignUp } from "../../services/auth/auth.service";
import { ManagerService } from '../../services/manager.service';
import { setInStorage, removeNullOrEmpty } from 'src/app/common/utils';
import { DEFAULT_LANGUAGE } from '@ngx-translate/core';


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isMutePass: boolean = false;
  langSelected = DEFAULT_LANGUAGE_TYPE;
  public loginForm: FormGroup;
  public resetPasswordForm: FormGroup;
  // public forgotPasswordForm: FormGroup;
  // public resetForgottenPasswordForm: FormGroup;
  // public signUpForm: FormGroup;
  // public confirmSignUpForm: FormGroup;
  public action: string = "login";
  protected user: any;
  constructor(
    private formBuilder: FormBuilder, private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private managerService: ManagerService,
  ) { }
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });

    this.resetPasswordForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],],
      cnfrmPassword: ["", [Validators.required]],
    },
      {
        validator: ConfirmedValidator("password", "cnfrmPassword"),
      });
  }
  //get formControl() {
  //  return this.loginForm.controls;
  //}

  // ngOnInit(): void {
  // this.loginForm = this.formBuilder.group({
  //   email: ["", [Validators.required, Validators.email]],
  //   password: ["", Validators.required],
  // });

  //   this.forgotPasswordForm = this.formBuilder.group({
  //     email: ["", [Validators.required, Validators.email]],
  //   });

  //   this.resetForgottenPasswordForm = this.formBuilder.group({
  //     code: ["", Validators.required],
  //     password: ["", [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],],
  //     cnfrmPassword: ["", [Validators.required]],
  //     },
  //     {
  //       validator: ConfirmedValidator("password", "cnfrmPassword"),
  //     });

  //   this.resetPasswordForm = this.formBuilder.group({
  //       password: [ "", [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],],
  //       cnfrmPassword: ["", [Validators.required]],
  //     },
  //     {
  //       validator: ConfirmedValidator("password", "cnfrmPassword"),
  //     }
  //   );

  //   this.signUpForm = this.formBuilder.group({
  //     manu_name: ['', Validators.required],
  //     email: ["", [Validators.required, Validators.email]],
  //     password: [ "", [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],],
  //   });

  //   this.confirmSignUpForm = this.formBuilder.group({
  //     code: ["", Validators.required],
  //   });

  // }
  get formControl() {
    return this[this.getActiveForm()].controls;
  }

  getActiveForm = () => {
    switch (this.action) {
      case "login":
        return "loginForm";
      case "resetPassword":
        return "resetPasswordForm";
      //     case "forgotPassword":
      //         return "forgotPasswordForm";
      //     case "resetForgottenPassword":
      //         return "resetForgottenPasswordForm";
      //     case "signUp":
      //         return "signUpForm";
      //     case "confirmSignUp":
      //         return "confirmSignUpForm";
    }
  };

  onSubmit = () => {
    if (this.action === "login") {
      this.doLogin();
    }
    //else if(this.action === "forgotPassword"){
    //       this.forgotMyPassword();
    //   } else if (this.action === "resetForgottenPassword"){
    //     this.resetForgottenPassword();
    //   }
    //   else if (this.action === "signUp"){

    //     this.signUpNewUser();
    //   }
    //   else if (this.action === "confirmSignUp"){
    //     this.confirmSignUp();
    //   }
    else
      this.resetPassword();
  };

  // confirmSignUp= async () => {
  //   let { code } = this.confirmSignUpForm.value;
  //   this.spinner.show();
  //   let { message, result } =  await ConfirmSignUp(this.user.email, code);
  //   this.spinner.hide();
  //   if (!result) {
  //         this.toastr.error(message);
  //         if(message == ERROR_MESSAGES.INVALID_CODE){
  //           this.action = "confirmSignUp";
  //           this.confirmSignUpForm.reset();
  //         }
  //         else
  //           this.action="login"
  //         return;
  //   }
  //   this.toastr.success(SUCCESS_MESSAGES.SIGNUP_SUCCESS);
  //   this.spinner.show();
  //   try{
  //   await SignIn(this.user.email, this.user.password);
  //   this.getManufacturer();
  //   }catch (err) {
  //     this.toastr.error(err.message);
  //   }
  //   this.spinner.hide();
  // }

  // signUpNewUser= async () => {
  //   let data = { ...removeNullOrEmpty(this.signUpForm.value)};
  //   data['role_name'] = ROLE_NAME;
  //   data.email = data.email ? data.email.toLowerCase() : '';
  //   this.spinner.show();
  //   let { message, result } = await SignUp(data);
  //   this.spinner.hide();
  //   if (!result) {
  //         this.toastr.error(message);
  //         this.action="login"
  //         return;
  //   }
  //   this.toastr.success(SUCCESS_MESSAGES.CODE_SENT);
  //   this.user= data
  //   this.action = "confirmSignUp";
  // }



  // resetForgottenPassword= async () => {
  //   let { code, password } = this.resetForgottenPasswordForm.value;
  //   this.spinner.show();
  //   let { message, result } = await ForgotPasswordSubmit(this.user, code, password);
  //   this.spinner.hide();
  //   if (!result) {
  //     this.toastr.error(message);
  //     if(message == ERROR_MESSAGES.INVALID_CODE){
  //         this.action = "resetForgottenPassword";
  //         this.resetForgottenPasswordForm.reset();
  //     }
  //     else
  //         this.action="login"
  //     return;
  //   }
  //   this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD);
  //   this.spinner.show();
  //   try{
  //   await SignIn(this.user, password);
  //   this.getManufacturer();
  //   }catch (err) {
  //     this.toastr.error(err.message);
  //   }
  //   this.spinner.hide();
  // }

  // forgotMyPassword = async () => {
  //   let { email } = this.forgotPasswordForm.value;
  //   this.spinner.show();
  //   let { message, result } = await ForgotPassword(email.toLowerCase());
  //   this.spinner.hide();
  //   this.user = email.toLowerCase();

  //   if (!result) {
  //     this.toastr.error(message);
  //     this.action="login"
  //     return;
  //   }
  //   this.toastr.success(SUCCESS_MESSAGES.CODE_SENT);
  //   this.action = "resetForgottenPassword";

  // }

  // forgotPassword = () =>{
  //   this.action = "forgotPassword";
  // };

  // signUpUser = () =>{
  //   this.action = "signUp";
  // };

  // cancel= () =>{
  //   this.action = "login";
  // };

  doLogin = async () => {
    let { email, password } = this.loginForm.value;
    this.spinner.show();
    let { message, result } = await SignIn(email.toLowerCase(), password);
    this.spinner.hide();
    if (!result) {
      this.toastr.error(message);
      return;
    }
    const { challengeName } = result;
    if (challengeName === "NEW_PASSWORD_REQUIRED") {
      this.goToReset(result);
      return;
    }
    this.getManufacturer();
  };

  getManufacturer = async () => {
    this.spinner.show();
    try {
      let result = await this.managerService.getUser();
      if (result && result.role && ALLOWED_ROLE.includes(result.role.id)) {
        setInStorage('user', result);
        if (this.langSelected == FRENCH_LANGUAGE)
          this.toastr.success(SUCCESS_MESSAGES.LOGIN_SUCCESSFUL_FR);
        else
          this.toastr.success(SUCCESS_MESSAGES.LOGIN_SUCCESSFUL);
        this.router.navigate(["/manager/dashboard"]);
        return;
      }
      this.toastr.error('Not Authorized.')
    } catch (err) {
      this.toastr.error(err.message);
    }
    this.spinner.hide();
  }
  goToReset = (result) => {
    this.action = "resetPassword";
    this.user = result;
    if (this.langSelected == FRENCH_LANGUAGE)
      this.toastr.error(ERROR_MESSAGES.CHANGE_PASSWORD_FR);
    else
      this.toastr.error(ERROR_MESSAGES.CHANGE_PASSWORD);
  };

  resetPassword = async () => {
    const { result, message } = await completeNewPassword(
      this.user,
      this.resetPasswordForm.value.password
    );
    if (!result) {
      this.toastr.error(message);
    }
    if (this.langSelected == FRENCH_LANGUAGE)
      this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD_FR);
    else
      this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD);
    this.router.navigate(["/manager/dashboard"]);
  };

  languageSelected(ln: string) {
    if (ln === DEFAULT_LANGUAGE_TYPE)
      this.langSelected = DEFAULT_LANGUAGE_TYPE;
    if (ln == FRENCH_LANGUAGE)
      this.langSelected = FRENCH_LANGUAGE
  }

}
