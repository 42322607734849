import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from '@ngx-translate/core';
import { ManagerService } from 'src/app/services/manager.service';
import { PAGE_COUNT } from './../../../common/constants';


@Component({
  selector: 'app-add-characteristics',
  templateUrl: './add-characteristics.component.html',
  styleUrls: ['./add-characteristics.component.scss']
})
export class AddCharacteristicsComponent implements OnInit {
  @Input() public data = [];
  public pageCount:number =  PAGE_COUNT;
  public currentPage: number = 1;
  constructor(public activeModal: NgbActiveModal,
              private managerService: ManagerService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.data.map(x=> x.include = false);

    this.managerService.switchLn.subscribe((ln: any) => {
      console.log('inside ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })
  }

  saveChars = () => {
    let result = this.data.filter(x=> x.include);
    let response = result.map(x => {
      let {include, product_id, id, ...rest} = x;
      return rest;
    });
    let res = {
      success: true,
      data: response
    };
    this.activeModal.close(res);
  }

  setPage = (page) => {
    this.currentPage = page;
  }

}
