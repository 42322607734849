import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-manager-layout",
  templateUrl: "./manager-layout.component.html",
  styleUrls: ["./manager-layout.component.scss"],
})
export class ManagerLayoutComponent implements OnInit {
  showSideNav: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  handleToggle = (event) => {
    console.log(event);
    this.showSideNav = event;
  }
}
