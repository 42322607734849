// project modules

const util = require('util');
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { URLS } from '../common/constants';
import { call } from './backend.service';
import { HttpClient  } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { getFromStorage } from '../common/utils';
@Injectable({
    providedIn: "root",
  })
export class ManagerService{
    public loggedInUser: BehaviorSubject<object> = new BehaviorSubject(null);
    public switchLn: BehaviorSubject<object> = new BehaviorSubject(null);
    constructor(private _http: HttpClient) {
    }
    getUser = async () => {
        let { success, result } = await call(URLS.GET_USER, 'get');
        if (success) {
            this.loggedInUser.next(result);
            return result;
        }
    }
    
    deleteUserById = async (id) => {
        const url = util.format(URLS.DELETE_USER_BY_ID, id);
        return await call(url, 'del');
    }

    getUsers = async (role = null, name = null, page = null, count = null) => {
        let queryParams = {};
        if (name) queryParams['name'] = name;
        if (role) queryParams['role_id'] = role;
        if (page) queryParams['page'] = page;
        if (count) queryParams['limit'] = count;
    
        let result = await call(URLS.USERS, 'get', null, queryParams);
        if (result.success) {
            return result;
        }
    }
    
    postUser = async (data) => {
        console.log("inside post method");
        return await call(URLS.USER, 'post', data);
    }
    
    putUser = async (data) => {
        return await call(URLS.USER, 'put', data);
    }
    
    getUserById = async (id) => {
        const url = util.format(URLS.GET_USER_BY_ID, id);
        return await call(url, 'get');
    }

    getReport = async () => {
        let queryParams = {};
        let result = await call(URLS.GET_REPORTS_NEW, "get", null, queryParams);
        if (result.success) {
            return result;
        }
    }

    signup(params){
            return this._http.post(`${environment.amplify.API.endpoints[0].endpoint}/signup/manufacturer`,{...params});
    }
    
    loadUser = async() => {
        if(getFromStorage('user')) {
            return getFromStorage('user');
        }
        else{
            return await this.getUser();
        }
      }
}


export const getApi = async () => {
    let { success, results } = await call(URLS.GET_APIS, "get");
    if (success) {
      return results;
    }
  };
  
  export const AddApi = async (params) => {
    return await call(URLS.POST_APIS, "put", params);
  };
  
  export const deleteApiById = async (id) => {
    const url = util.format(URLS.DELETE_API_BY_ID, id);
    return await call(url, "del");
  };