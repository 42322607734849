
<div role="document">
    <div class="modal-content">

        <!-- modal header -->
        <div class="modal-header bg-primary ">
            <h2 class="modal-title text-white">  {{'reset password' | translate}} </h2>
        </div>
        <!-- modal header -->
        <div class="modal-body">
            <!-- modal content -->
            
            <form class="form-horizontal" [formGroup]="resetPasswordForm">
                <div class="form-group">
                  <label class="text-secondary">{{'old password' | translate}}</label>
                  <input type="Password" class="form-control border-light" id="oldPassword" formControlName="oldPassword"
                    placeholder="{{'enter' | translate}} {{'old password' | translate}}">
                  <span class="text-danger pt-1"
                    *ngIf="formControl.oldPassword.touched && formControl.oldPassword.errors?.required">
                  <small>
                    {{'old password' | translate}} {{'is required.' | translate}}
                  </small>
                  </span>
                  <span class="text-danger pt-1"
                    *ngIf="formControl.oldPassword.touched && formControl.oldPassword.errors?.pattern">
                  <small>
                    {{'password should be atleast 8 characters, should contain one uppercase letter, one lowercase letter, one
                    special character and one number.' | translate}}
                  </small>
                  </span>
                </div>

                <div class="form-group">
                    <label class="text-secondary">{{'new password' | translate}}</label>
                    <input type="Password" class="form-control border-light" id="newPassword" formControlName="newPassword"
                      placeholder="{{'enter' | translate}} {{'new password' | translate}}">
                    <span class="text-danger pt-1"
                      *ngIf="formControl.newPassword.touched && formControl.newPassword.errors?.required">
                    <small>
                      {{'new password' | translate}} {{'is required.' | translate}}
                    </small>
                    </span>
                    <span class="text-danger pt-1"
                      *ngIf="formControl.newPassword.touched && formControl.newPassword.errors?.pattern">
                    <small>
                      {{'password should be atleast 8 characters, should contain one uppercase letter, one lowercase letter, one
                      special character and one number.' | translate}}
                    </small>
                    </span>
                  </div>
          
                <div class="form-group">
                  <label class="text-secondary">{{'reenter password' | translate}}</label>
                  <input type="Password" class="form-control border-light" id="reenterPassword"
                    formControlName="reenterPassword" placeholder="{{'reenter password' | translate}}">
                  <span class="text-danger pt-1"
                    *ngIf="formControl.reenterPassword.touched && formControl.reenterPassword.errors?.required">
                  <small>
                    {{'reenter password' | translate}} {{'is required.' | translate}}
                  </small>
                  </span>
                  <span class="text-danger pt-1"
                    *ngIf="formControl.reenterPassword.touched && formControl.reenterPassword.errors?.confirmedValidator">
                  <small>
                    {{'passwords do not match.' | translate}}
                  </small>
                  </span>
                </div>
              </form>

        </div>
        <div class="modal-footer d-flex">
            <button type="button" class="btn btn-primary" (click) = "activeModal.close({success: false})">{{'cancel' | translate}}</button>
            <button type="button" class="btn btn-primary" (click) = "resetPassword()" [disabled]="!resetPasswordForm.valid"> {{'submit' | translate}}</button>
        </div>
    </div>
</div>
