<div class="row">
  <div class="col-sm-5">
    <h2 class="text-dark font-weight-bold">{{ "users" | translate }}</h2>
  </div>
  <div class="col-sm-3"></div>
  <div class="col-sm-4 text-right w-auto">
    <button
      type="button"
      class="btn-primary-shadow btn font-weight-bold ml-4 pt-3 pb-3 pl-5 pr-5 font-style-normal text-capitalize text-white"
      (click)="addUserClick()"
      [disabled]="parentUser?.plan_type === planType.Discovery"
    >
      {{ "add" | translate }} {{ "a" | translate }} {{ "user" | translate }}
    </button>
  </div>
</div>
<br />
<br />
<div *ngIf="!addUser">
  <div *ngIf="userList && userList.length > 0">
    <div class="table-responsive">
      <table class="table table-bordered table-fixed mb-0">
        <thead class="text-dark">
          <tr>
            <th scope="col">{{ "name" | translate }}</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody class="text-dark">
          <tr *ngFor="let user of userList">
            <td>{{ user?.first_name + " " + user?.last_name }}</td>
            <td>{{ user?.email }}</td>
            <td class="cursor-pointer">
              <a class="edit-pen pl-3" (click)="editUser(user)"
                ><img src="../../../assets/images/edit-icon.png"
              /></a>
              <a class="remove pl-3" (click)="deleteUser(user?.id)"
                ><img src="../../../assets/images/delete-icon.png"
              /></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination -->
    <app-pagination
      [totalItems]="totalPage"
      [pageSize]="pageCount"
      (pageClicked)="setPage($event)"
      *ngIf="totalPage > pageCount"
    >
    </app-pagination>

    <!-- <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" (pageClicked)="setPage($event)" *ngIf="totalPage > pageCount"></app-pagination> -->
  </div>
  <div *ngIf="!userList || userList.length === 0">
    {{ "no" | translate }} {{ "users" | translate }}
  </div>
</div>

<div *ngIf="addUser">
  <div class="users-part">
    <h2 class="pl-3 text-dark mb-3 font-size-18 font-weight-bold">
      {{ "user details" | translate }}
    </h2>
    <form
      [formGroup]="detailsForm"
      class="d-inline-block w-100 pl-4 pb-5 rounded border border-primary"
    >
      <div class="form-group w-100 float-left font-size-18 mb-0 mt-4">
        <div class="row pt-3">
          <label class="text-dark col-sm-2 col-form-label mt-0 mt-md-2">{{
            "first name" | translate
          }}</label>
          <div class="col-sm-9">
            <input
              formControlName="first_name"
              id="first_name"
              type="text"
              class="form-control border-primary text-dark"
              placeholder="{{ 'enter' | translate }} {{
                'first name' | translate
              }}"
            />
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.first_name.touched &&
                formControl.first_name.errors?.required
              "
            >
              <small
                >{{ "first name" | translate }} {{ "is required." | translate }}
              </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="text-dark col-sm-2 col-form-label mt-0 mt-md-2">{{
            "last name" | translate
          }}</label>
          <div class="col-sm-9">
            <input
              formControlName="last_name"
              id="last_name"
              type="text"
              class="form-control wt-50 border-primary text-dark"
              placeholder="{{ 'enter' | translate }} {{
                'last name' | translate
              }}"
            />
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.last_name.touched &&
                formControl.last_name.errors?.required
              "
            >
              <small
                >{{ "last name" | translate }} {{ "is required." | translate }}
              </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="text-dark col-sm-2 col-form-label mt-0 mt-md-2"
            >Email</label
          >
          <div class="col-sm-9">
            <input
              formControlName="email"
              id="email"
              type="text"
              class="form-control wt-50 border-primary text-dark"
              placeholder="{{ 'enter' | translate }} Email"
              [readonly]="isEdit"
              [ngClass]="{ 'disble-box': isEdit }"
            />
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.email.touched && formControl.email.errors?.required
              "
            >
              <small>Email {{ "is required." | translate }} </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="text-dark col-sm-2 col-form-label mt-0 mt-md-2"
            >{{ "select" | translate }} {{ "country" | translate }}</label
          >
          <div class="col-sm-9">
            <select
              id="country_id"
              class="form-control wt-50 border-primary text-dark"
              formControlName="country_id"
              [ngClass]="{ 'disble-box': isEdit }"
            >
              <option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
              </option>
            </select>
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.country_id.touched &&
                formControl.country_id.errors?.required
              "
            >
              <small
                >{{ "country" | translate }} {{ "is required." | translate }}
              </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="text-dark col-sm-2 col-form-label mt-0 mt-md-2"
            >{{ "select" | translate }} {{ "role" | translate }}</label
          >
          <div class="col-sm-9">
            <select
              id="role_id"
              class="form-control wt-50 border-primary text-dark"
              formControlName="role_id"
              [ngClass]="{ 'disble-box': isEdit }"
            >
              <option *ngFor="let role of filteredRoles" [value]="role.id">
                {{ role.name }}
              </option>
            </select>
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.role_id.touched &&
                formControl.role_id.errors?.required
              "
            >
              <small
                >{{ "role" | translate }}
                {{ "is required." | translate }}</small
              >
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="form-group">
    <div class="mt-3 d-flex justify-content-end">
      <button
        type="button"
        class="cancel-users-btn font-weight-bold font-size-18 rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
        (click)="resetForm()"
      >
        {{ "cancel" | translate }}
      </button>
      <button
        type="button"
        class="save-users-btn font-size-18 border rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="
          !detailsForm.valid || parentUser?.plan_type === planType.Discovery
        "
        (click)="createUser()"
      >
        {{ "save" | translate }}
      </button>
    </div>
  </div>
</div>
