import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'months'
})
export class MonthsPipe implements PipeTransform {

  months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

  transform(value: Date): string {
    return this.months[value.getMonth()];
  }

}
