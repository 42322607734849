import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./app-header/app-header.component";
import { SideBarComponent } from "./side-nav/side-bar.component";
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { RegionalDataComponent } from './modals/regional-data/regional-data.component';
import { AddCharacteristicsComponent } from './modals/add-characteristics/add-characteristics.component';
import { AddMediaComponent } from './modals/add-media/add-media.component';
import { AddFilesComponent } from './modals/add-files/add-files.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../shared.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ResetPasswordComponent } from './modals/reset-password/reset-password.component';
import { AngularEditorModule } from "@kolkov/angular-editor";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        SharedModule,
        // ngx-translate and the loader module
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AngularEditorModule,
    ],
    declarations: [
        SideBarComponent,
        HeaderComponent,
        ConfirmationModalComponent,
        RegionalDataComponent,
        AddCharacteristicsComponent,
        AddMediaComponent,
        AddFilesComponent,
        ResetPasswordComponent,
    ],
    exports: [SideBarComponent, HeaderComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
