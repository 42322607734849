import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { API, Auth } from "aws-amplify";

const getInit = async () => {
  let currentSession = await Auth.currentCredentials();
  console.log(currentSession);
  return {
    headers: {
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    response: true,
  };
};


export const call = async (
  path,
  method,
  body = null,
  queryStringParameters = null
) => {
  let headers = await getInit();
  if (queryStringParameters) {
    headers = Object.assign(headers, {
      queryStringParameters: queryStringParameters,
    });
  }
  if (body) {
    headers = Object.assign(headers, {
      body: body,
    });
  }
  try {
    let { data } = await API[method]("app-api", path, headers);
    return data;
  } catch (error) {
    if (
      error.response &&
      "data" in error.response &&
      error.response.data &&
      "message" in error.response.data
    ) {
      throw error.response.data;
    }
    throw error;
  }

}

export const uploadFileToS3 = async (uri, file) => {
  const httpClient = new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest() })
  );
  return await httpClient.put(uri, file, { headers: { 'Content-Type': file.type } }).toPromise();
};
