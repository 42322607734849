<div role="document">
  <div class="modal-content">
    <!-- modal header -->
    <div class="modal-header">
      <h4 class="modal-title text-dark font-weight-bold">{{ title }}</h4>
    </div>
    <!-- modal header -->
    <div
      class="search-results scroll-body"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <div class="modal-body">
        <div>
          <div class="row mb-2" *ngIf="media && media?.length > 0">
            <div
              class="col-sm-4 mt-4 d-flex justify-content-center"
              *ngFor="let md of media"
            >
              <div class="card p-3">
                <img
                  *ngIf="md?.file_type?.id === fileType.image"
                  [src]="md?.url"
                />
                <video
                  width="60"
                  height="74"
                  *ngIf="md?.file_type?.id === fileType.video"
                  controls
                >
                  <source [src]="md?.url" />
                </video>
                <div class="card-body">
                  <div
                    *ngIf="!md.selected"
                    (click)="md.selected = true"
                    class="font-weight-bold rounded border border-primary text-dark pl-5 pb-2 pr-5 pt-2 d-flex justify-content-center cursor-pointer"
                  >
                    {{ "choose" | translate }}
                  </div>

                  <div
                    *ngIf="md.selected"
                    class="custom-control custom-checkbox border added-btn pl-5 pb-2 pr-5 pt-2 rounded d-flex justify-content-center"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="defaultUnchecked"
                      checked
                      (change)="md.selected = false"
                    />
                    <label class="custom-control-label" for="defaultUnchecked">
                      <span class="text-dark-orange font-weight-bold">{{
                        "added" | translate
                      }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!media || media?.length === 0">
            {{ "No" | translate }} {{ "data" | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex">
      <button
        type="button"
        class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
        (click)="activeModal.close({ success: false })"
      >
        {{ "cancel" | translate }}
      </button>
      <button
        type="button"
        class="btn button_form rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        (click)="saveMedia()"
      >
        {{ "save" | translate }}
      </button>
    </div>
  </div>
</div>
