export class File {
    id: number;
    order: number;
    type_id: number;
    url: string;
    enable: boolean;
    description: string;
    credit: string;
    licence: string;
    buffer_url: ArrayBuffer | string;
    external_url: any
}