
<div class="d-flex justify-content-between mb-3 align-items-center">
  <div>
    <span *ngIf="displayManufacturerId">Manufacturer Id : {{manufacturerId}}</span>
    <button id="sidebarCollapse" type="button" class="btn   rounded-pill px-4 mb-4" (click)="clickMenu()"><i
        class="fa fa-bars mr-2 side-bar-menu"></i></button>
  </div>
  <div>
    <div class="right-bar dropdown d-flex align-items-center">
      <a class="nav-link dropdown-toggle pt-0" id="navbarDropdown" role="button" data-hover="dropdown"
        data-toggle="dropdown" aria-haspopup="true" aria-ex.panded="false" (click)="showDropDown = openUser($event)">
        <img src="../../../assets/images/user-login.png">
      </a>
      <div class="mt-2">
        <p class="mb-0"></p>
        <p class="font-weight-light text-secondary font-size-12">
          <span>{{user?.first_name ? user.first_name : ''}} </span>
          <span>{{ user?.last_name ? user.last_name : ''}} </span>
        </p>
      </div>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="showDropDown">
        <a class="dropdown-item"  id="languageDropdown" role="button" data-hover="dropdown"
        data-toggle="dropdown" aria-haspopup="true" aria-ex.panded="false" (click)="showLanguageDropDown = openLanguage($event)"> Language
        </a>
        <a class="dropdown-item" (click)="changePassword()" >Password</a>
        <a class="dropdown-item" (click)="logOut()">Logout</a>
        <!-- <a class="dropdown-item">{{'password' | translate}}</a>
        <a class="dropdown-item" (click)="logOut()">{{'logout' | translate}}</a>  -->
      </div>
    </div>
    
  </div>
  <div class="dropdown-menu langmanu" aria-labelledby="languageDropdown" *ngIf="showLanguageDropDown">
    <div *ngFor = "let l_type of Language_type">
      <a class="dropdown-item" [ngClass]="{'highlight': selectedIndex === l_type?.key}" (click) = "setLanguage(l_type?.key)">{{l_type?.value}}</a>
    </div>
    <!--  <a class="dropdown-item" (click) = "setLanguage('en')">{{'english' | translate}}</a>
    <a class="dropdown-item" (click) = "setLanguage('fr')">{{'french' | translate}}</a>  -->
 </div>
</div>