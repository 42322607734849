// core modules
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ManagerService } from "src/app/services/manager.service";
import { VERSION } from "./../../../environments/version";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "dashboard",
    title: "dashboard",
    icon: "pie-chart.svg",
    class: "",
  },
  {
    path: "products",
    title: "products",
    icon: "cubes-stacked-solid.svg",
    class: "",
  },
  {
    path: "manufacturer",
    title: "manufacturer",
    icon: "id-card-clip-solid.svg",
    class: "",
  },
  {
    path: "api",
    title: "API",
    icon: "api.svg",
    class: "",
  },
  {
    path: "users",
    title: "users",
    icon: "user.svg",
    class: "",
  },
  //   {
  //     path: "plan",
  //     title: "plan",
  //     icon: "plan.svg",
  //     class: "",
  //   },
  {
    path: "contracts",
    title: "Contracts",
    icon: "file-contract-solid.svg",
    class: "",
  },
  {
    path: "billing",
    title: "Invoices",
    icon: "file-invoice-dollar-solid.svg",
    class: "",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  public menuItems: any[];
  public ver = VERSION.version;
  @Input() showSideNav: boolean;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private managerService: ManagerService
  ) {}

  ngOnInit(): void {
    this.managerService.switchLn.subscribe((ln: any) => {
      console.log("inside sidebar ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}
