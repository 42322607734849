<div role="document">
  <div class="modal-content">
    <!-- modal header -->
    <div class="modal-header">
      <h4 class="modal-title text-dark font-weight-bold">{{ title }}</h4>
    </div>
    <!-- modal header -->
    <div
      class="search-results scroll-body"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <div class="modal-body">
        <div *ngIf="drivers && drivers?.length > 0">
          <div *ngFor="let driver of drivers; let i = index" class="mt-4">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="{{ i }}"
                [checked]="driver.selected"
                [(ngModel)]="driver.selected"
              />
              <label class="custom-control-label" for="{{ i }}">
                <span class="text-secondary text-muted invisible">ss</span>
              </label>
            </div>
            <div class="border border-primary text-dark mt-3 p-3">
              {{
                driver?.description ? driver?.description : "No Description."
              }}
            </div>
          </div>
        </div>
        <div *ngIf="!drivers || drivers?.length === 0">
          {{ "No" | translate }} {{ "data" | translate }}
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex">
      <button
        type="button"
        class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
        (click)="activeModal.close({ success: false })"
      >
        {{ "cancel" | translate }}
      </button>
      <button
        type="button"
        class="btn button_form rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        (click)="saveData()"
      >
        {{ "save" | translate }}
      </button>
    </div>
  </div>
</div>
