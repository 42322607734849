import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  ERROR_MESSAGES,
  FILE_TYPE,
  MAX_FILE_SIZE,
  SUCCESS_MESSAGES,
} from "src/app/common/constants";
import { ConfirmationModalComponent } from "src/app/components/modals/confirmation-modal/confirmation-modal.component";
import {
  getFromStorage,
  getS3Url,
  removeNullOrEmpty,
  setInStorage,
} from "../../common/utils";
import { RegionalDataComponent } from "../../components/modals/regional-data/regional-data.component";
import { RegionalData } from "../../models/regionalData";
import { User } from "../../models/user";
import { ManagerService } from "../../services/manager.service";
import { ProductService } from "../../services/product.service";
import { Country } from "./../../models/country";
import { Language } from "./../../models/language";

@Component({
  selector: "app-manufacturer",
  templateUrl: "./manufacturer.component.html",
  styleUrls: ["./manufacturer.component.scss"],
})
export class ManufacturerComponent implements OnInit {
  public user: User;
  public FileType = FILE_TYPE;
  public photoURL;
  public regionalData: RegionalData[] = [];
  public detailsForm: FormGroup;
  public photo: File;
  public languages: Language[];
  public countries: Country[];
  public rootForm: FormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private managerService: ManagerService,
    private productService: ProductService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      manufacturer_name: [""],
      description: [""],
    });
    this.rootForm = this.formBuilder.group({
      email: [""],
      root_ean: [""],
      contact_number: [""],
      password: [""],
    });
    this.loadUser();

    this.managerService.switchLn.subscribe((ln: any) => {
      console.log("inside ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
  }

  loadUser = async () => {
    this.user = getFromStorage("user");
    this.setFormValue();
  };

  setFormValue = () => {
    let valuesToPatch = {};
    for (let key of Object.keys(this.user)) {
      valuesToPatch[key] = this.user[key];
    }
    this.detailsForm.patchValue(valuesToPatch);
    this.rootForm.patchValue(valuesToPatch);
    if (this.user.photo_url) this.photoURL = this.user.photo_url;
    if (this.user.regional_data) {
      this.getCountries();
    }
  };

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await this.productService.getMetaData("country");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
      this.getLanguages();
    }
  };

  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await this.productService.getMetaData("language");
      this.spinner.hide();
      this.regionalData = [...this.user.regional_data];
    } catch (err) {
      this.spinner.hide();
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    }
  };

  selectFile = async (event, type) => {
    let {
      target: { files },
    } = event;
    let [file] = files;
    let [fileType] = file.type.split("/");

    if (type === "image" && fileType !== type) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message = "Invalid File Format.";
      event.srcElement.value = null;
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message =
        "File too Big! Please select a file less than 100mb";
      event.srcElement.value = null;
      return;
    }

    if (file) {
      this.photo = file;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.photoURL = reader.result;
      };
    }
  };

  addRegionalData = (index = -1) => {
    const modalRef = this.modalService.open(RegionalDataComponent);
    if (index >= 0) {
      modalRef.componentInstance.data = this.regionalData[index];
    }
    modalRef.componentInstance.index = index;
    modalRef.result.then((result: any) => {
      console.log("comp closed", result);
      if (result && result.success) {
        if (result.index >= 0) {
          this.regionalData[index] = result.data;
          return;
        }
        this.regionalData = [...this.regionalData, result.data];
      }
    });
  };

  deleteRegionalData = (index) => {
    this.regionalData.splice(index, 1);
  };

  public formControl(formName) {
    return this[formName].controls;
  }

  updateUser = async (type = "user") => {
    const allowedParams = ["photo_url", "description"];
    let data: any = {};
    console.log("data: " + JSON.stringify(data));
    switch (type) {
      case "regional":
        let regionalData = [];
        this.user.users_manufacturers.forEach((manufaturer) => {
          this.regionalData.forEach((rg) => {
            rg.manufacturer_id = manufaturer.manufacturer_id;
            regionalData.push({ ...rg });
          });
        });
        data.regional_data = regionalData.map((rg) => {
          delete rg["user_id"];
          return removeNullOrEmpty(rg);
        });
        break;
      case "user":
        for (let key of allowedParams) {
          if (this.detailsForm.value[key]) {
            data[key] = this.detailsForm.value[key];
          }
        }
        if (this.photo && this.photoURL !== this.user.photo_url) {
          data["photo_url"] = await this.getFileUrl();
        }
        if (this.user.photo_url && !this.photo) data["photo_url"] = "";
        break;
    }
    data.id = this.user.id;
    delete data.country_id;
    this.spinner.show();
    try {
      await await this.managerService.putUser(data);
      for (let key of Object.keys(data)) {
        this.user[key] = data[key];
      }
      this.user.regional_data = this.regionalData;
      this.managerService.loggedInUser.next(this.user);
      this.toastr.success(SUCCESS_MESSAGES.MANU_UPDATED);
      setInStorage("user", this.user);
      this.router.navigate(["/manager/manufacturer"]);
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getFileUrl = async () => {
    let params = {
      file_name: this.photo.name,
      content_type: this.photo.type,
    };
    this.spinner.show();
    try {
      // get signed url from backend
      let { key, url } = (await this.productService.updateFile(params)) || {};
      // upload file to signed url
      await this.productService.uploadFile(url, this.photo);
      this.spinner.hide();
      // return s3 url
      return getS3Url(key);
    } catch (err) {
      this.spinner.hide();
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }

    return null;
  };

  metadataName = (data, id) => {
    return this[data].filter((d) => d.id === id)?.[0]?.name ?? "";
  };
}
