import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { getFromStorage } from "src/app/common/utils";
import { ConfirmationModalComponent } from "src/app/components/modals/confirmation-modal/confirmation-modal.component";
import { User } from "src/app/models/user";
import { ManagerService } from "src/app/services/manager.service";
import { Country } from "../../models/country";
import { Role } from "../../models/role";
import { ProductService } from "../../services/product.service";
import {
  ERROR_MESSAGES,
  PAGE_COUNT,
  PlanType,
  SUCCESS_MESSAGES,
} from "./../../common/constants";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  public userList: User[];
  public addUser: boolean = false;
  public detailsForm: FormGroup;
  public countries: Country[];
  public roles: Role[];
  public filteredRoles: Role[];
  private user: User;
  public isEdit: boolean = false;
  public page: number = 0;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  parentUser!: User;
  planType = PlanType;
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private managerService: ManagerService,
    private productService: ProductService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      country_id: [null, Validators.required],
      role_id: [null, Validators.required],
    });
    this.getUsers();
    this.getRoles();
    this.getCountries();
    this.parentUser = getFromStorage("user");
    this.managerService.switchLn.subscribe((ln: any) => {
      console.log("inside ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
  }

  getUsers = async (page = null) => {
    if (page) {
      this.page = page;
    } else {
      this.page++;
    }
    this.spinner.show();
    try {
      let { results, totalCount } = await this.managerService.getUsers(
        null,
        null,
        this.page,
        this.pageCount
      );
      this.userList = results;
      this.totalPage = totalCount;
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  get formControl() {
    return this.detailsForm.controls;
  }

  createUser = async () => {
    this.spinner.show();
    try {
      if (this.user) {
        let data = {
          id: this.user.id,
        };
        const allowedFields = ["first_name", "last_name"];
        for (let key of allowedFields) {
          data[key] = this.detailsForm.value[key];
        }
        await this.managerService.putUser(data);
      } else {
        await this.managerService.postUser(this.detailsForm.value);
      }
      let message = this.isEdit
        ? SUCCESS_MESSAGES.USER_UPDATED
        : SUCCESS_MESSAGES.USER_CREATED;
      this.toastr.success(message);
      this.resetForm();
      this.page = 0;
      this.getUsers();
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getRoles = async () => {
    this.spinner.show();
    try {
      this.roles = await this.productService.getMetaData("role");
      let rootRoles = [1, 4, 5];
      this.filteredRoles = this.user
        ? this.roles
        : this.roles.filter((el) => el.id === 1);
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await this.productService.getMetaData("country");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  editUser = (user: User) => {
    this.filteredRoles = [...this.roles];
    this.addUser = true;
    this.user = user;
    this.isEdit = true;

    const nonEditable = ["country_id", "role_id"];
    for (let field of nonEditable) {
      this.detailsForm.controls[field].disable();
    }

    const fieldsToPatch = [
      "first_name",
      "last_name",
      "email",
      "country_id",
      "role_id",
    ];
    for (let field of fieldsToPatch) {
      let patch = {};
      patch[field] = field !== "role_id" ? user[field] : user.role.id;
      this.detailsForm.patchValue(patch);
    }
  };

  deleteUser = async (id) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message =
      "Are you sure you want to delete the User?";
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      console.log("comp closed", result);
      if (result) {
        this.handleDeleteUser(id);
      }
    });
  };

  handleDeleteUser = async (id) => {
    console.log(id);
    this.spinner.show();
    try {
      let { success } = await this.managerService.deleteUserById(id);
      if (success) {
        console.log(success);
        this.toastr.success(SUCCESS_MESSAGES.USER_DELETED);
        this.page = 0;
        this.getUsers();
      }
    } catch (err) {
      console.log(err);
      this.toastr.error(err.message);
    } finally {
      this.spinner.hide();
    }
  };

  resetForm = () => {
    this.addUser = false;
    this.detailsForm.reset();
    this.user = null;
    this.isEdit = false;
    this.page = 0;
    const nonEditable = ["country_id", "role_id"];
    for (let field of nonEditable) {
      this.detailsForm.controls[field].enable();
    }
  };

  setPage = (page) => {
    this.getUsers(page);
  };
  addUserClick() {
    this.addUser = true;
    this.filteredRoles = this.roles.filter((role) => role.id === 1);
  }
}
