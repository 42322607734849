// core modules
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Manager } from "../../models/manager";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
const util = require("util");
// project modules
import { SignOut } from "../../services/auth/auth.service";
import { ManagerService } from "../../services/manager.service";
import {
  LANGUAGE_TYPE,
  SUCCESS_MESSAGES,
  DEFAULT_LANGUAGE_TYPE,
  ERROR_MESSAGES,
} from "../../common/constants";
import {
  getFromStorage,
  setInStorage,
  clearStorage,
} from "src/app/common/utils";
import { ResetPasswordComponent } from "../modals/reset-password/reset-password.component";

@Component({
  selector: "app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
  host: {
    "(document:click)": "onClick()",
  },
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideNav: EventEmitter<boolean> = new EventEmitter();
  public showLanguageDropDown: boolean = false;
  public showDropDown: boolean = false;
  public user: Manager;
  public Language_type = LANGUAGE_TYPE;
  public selectedIndex: string;
  public manufacturerId: string = "";
  public displayManufacturerId: boolean = false;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private managerService: ManagerService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((val) => {
      if (this.router.url.includes("/manager/api")) {
        this.manufacturerId =
          getFromStorage("user").users_manufacturers[0].manufacturer_id;
        this.displayManufacturerId = true;
      } else {
        this.manufacturerId = "";
        this.displayManufacturerId = false;
      }
    });
    this.managerService.switchLn.subscribe((ln: any) => {
      console.log("inside ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
    this.loadUser();
  }

  clickMenu = () => {
    this.toggleSideNav.emit(true);
  };

  logOut = async () => {
    this.spinner.show();
    await SignOut();
    clearStorage();
    this.spinner.hide();
    this.router.navigate(["/login"]);
  };

  changePassword() {
    this.modalService.open(ResetPasswordComponent);
  }

  loadUser = async () => {
    if (getFromStorage("user")) {
      this.user = getFromStorage("user");
    } else {
      this.user = await this.managerService.getUser();
      setInStorage("user", this.user);
    }
    if (this.user.language && this.user.language != null) {
      this.managerService.switchLn.next({ ln: this.user.language });
      this.selectedIndex = this.user.language;
    } else {
      this.managerService.switchLn.next({ ln: DEFAULT_LANGUAGE_TYPE });
      this.selectedIndex = DEFAULT_LANGUAGE_TYPE;
    }
  };

  setLanguage = async (ln) => {
    this.selectedIndex = ln;
    this.spinner.show();
    try {
      let data = {
        id: this.user.id,
        language: ln,
      };
      await this.managerService.putUser(data);
      this.user.language = data.language;
      setInStorage("user", this.user);
      this.managerService.switchLn.next({ ln: ln });
      this.toastr.success(
        util.format(
          SUCCESS_MESSAGES.LANGUAGE_SWITCHED,
          LANGUAGE_TYPE.filter((x) => x.key === ln)[0].value
        )
      );
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.LANGUAGE_SWITCH
      );
    } finally {
      this.spinner.hide();
    }
  };

  openUser(e) {
    e.stopPropagation();
    return (this.showDropDown = !this.showDropDown);
  }

  openLanguage(e) {
    e.stopPropagation();
    return (this.showLanguageDropDown = !this.showLanguageDropDown);
  }

  onClick() {
    if (this.showDropDown && !this.showLanguageDropDown)
      this.showDropDown = false;
    if (this.showLanguageDropDown) this.showLanguageDropDown = false;
  }
}
