<div class="descr-part">
  <h2 class="text-dark mb-3 font-size-18 font-weight-bold">
    {{ "description" | translate }}
  </h2>

  <form
    [formGroup]="detailsForm"
    class="d-inline-block rounded border border-light p-3"
  >
    <div class="form-horizontal">
      <div class="form-group w-100 float-left mt-2">
        <div class="row pl-3">
          <label
            class="col-sm-2 col-form-label font-size-18 text-dark mt-0 mt-md-3"
            >EAN</label
          >
          <div class="col-sm-10 box-size">
            <div class="d-flex">
              <input
                formControlName="ean"
                id="ean"
                type="text"
                class="form-control text-dark border border-primary"
                placeholder="{{ 'enter' | translate }} EAN"
              />
            </div>
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl('detailsForm').ean.touched &&
                formControl('detailsForm').ean.errors?.required
              "
            >
              <small class="ml-0 ml-md-2"
                >EAN {{ "is required." | translate }}
              </small>
            </div>
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl('detailsForm').ean.touched &&
                formControl('detailsForm').ean.errors?.pattern
              "
            >
              <small>{{ "invalid" | translate }} EAN. </small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group w-100 float-left mb-0 mt-2">
        <div class="row pl-3">
          <label
            class="col-sm-2 text-dark mt-0 mt-md-3 ellipses col-form-label font-size-18"
            >{{ "publishing date" | translate }}
          </label>
          <div class="col-lg-10 col-xs-12 pl-0">
            <div class="col-sm-8 d-flex pl-0 pl-md-3">
              <input
                class="form-control border border-primary text-dark"
                placeholder="YYYY-MM-DD"
                name="dp"
                ngbDatepicker
                [minDate]="product && product?.id ? null : today"
                #d="ngbDatepicker"
                formControlName="date"
              />
              <img
                src="../../../assets/images/calu1-icon.png"
                (click)="d.toggle()"
                class="cul-img mb-3 mt-3 ml-5 cursor-pointer"
              />

              <img
                ngbPopover="{{
                  'The date when this product will be published on the plateform'
                    | translate
                }}"
                triggers="mouseenter:mouseleave"
                src="../../../assets/images/q-mark.png"
                class="q-mark ml-4 mt-3"
              />
            </div>
            <div>
              <span
                class="text-danger pt-1 d-block mt-2"
                *ngIf="
                  formControl('detailsForm').date.touched &&
                  formControl('detailsForm').date.errors?.required
                "
              >
                <small class="ml-0 ml-md-3"
                  >{{ "publishing date" | translate }}
                  {{ "is required." | translate }}
                </small>
              </span>

              <span
                class="text-danger pt-1 d-block mt-2"
                *ngIf="
                  formControl('detailsForm').date.touched &&
                  formControl('detailsForm').date.errors &&
                  !formControl('detailsForm').date.errors?.required
                "
              >
                <small class="ml-0 ml-md-3">
                  {{ "invalid" | translate }} Date.
                </small>
              </span>
            </div>
          </div>
        </div>
        <div class="row pl-3 pt-1">
          <label class="col-sm-2 text-dark col-form-label mt-3 font-size-18">{{
            "enable" | translate
          }}</label>
          <div class="col-sm-10">
            <span class="custom-control mt-2 custom-switch d-inline-block">
              <input
                type="checkbox"
                class="custom-control-input"
                id="enable"
                formControlName="enable"
              />
              <label
                class="custom-control-label mt-1 font-size-18"
                for="enable"
              ></label>
            </span>

            <img
              ngbPopover="{{
                'This switch enable or desable the availability of the product on the plateform'
                  | translate
              }}"
              triggers="mouseenter:mouseleave"
              src="../../../assets/images/q-mark.png"
              class="q-mark ml-4 pr-2 pr-md-2"
            />
          </div>
        </div>
      </div>

      <div class="form-group w-100 float-left mb-0 mt-3">
        <div class="row pl-3">
          <label class="col-sm-2 mt-3 col-form-label text-dark font-size-18">{{
            "title" | translate
          }}</label>
          <div class="col-sm-10 box-size">
            <input
              type="text"
              class="form-control border border-primary text-dark"
              placeholder="{{ 'enter' | translate }} {{ 'title' | translate }}"
              formControlName="title"
            />
            <span
              class="text-danger pt-1"
              *ngIf="
                formControl('detailsForm').title.touched &&
                formControl('detailsForm').title.errors?.required
              "
            >
              <small
                >{{ "title" | translate }} {{ "is required." | translate }}
              </small>
            </span>
          </div>
        </div>
      </div>
      <div class="form-group w-100 float-left mb-0 mt-3 mt-md-0">
        <label class="mt-0 mt-md-4 text-dark font-size-18">{{
          "description" | translate
        }}</label>
        <textarea
          class="form-control border border-primary desc-heig h-100 p-3 text-dark"
          rows="4"
          formControlName="description"
          placeholder="{{ 'enter' | translate }} {{
            'description' | translate
          }}"
        ></textarea>
      </div>
      <div class="form-group w-100 float-left mb-0 mt-3 mt-md-0">
        <div class="row pl-3 pr-3">
          <label class="mt-0 mt-md-4 text-dark font-size-18">{{
            "Html description" | translate
          }}</label>
          <angular-editor
            [config]="editorConfig"
            formControlName="html_description"
          ></angular-editor>
        </div>
      </div>
      <div class="form-group w-100 float-left mb-0 mt-3">
        <div class="row pl-3">
          <div class="col-lg-6 col-sm-12 d-flex pl-0 pl-md-0">
            <label
              class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-dark col-form-label font-size-18"
              >{{ "language" | translate }}</label
            >

            <select
              id="language_id"
              class="form-control border border-primary border border-light text-dark"
              formControlName="language_id"
            >
              <option *ngFor="let lan of languages" [value]="lan.id">
                {{ lan.name }}
              </option>
            </select>
            <span
              class="q-mark text-light border border-light d-inline-block m-3 pr-2 pr-md-2 pl-2 pl-md-2 rounded-circle text-center position-relative"
              >?</span
            >
          </div>
          <div class="col-lg-6 col-sm-12 d-flex mt-4 mt-lg-0 pl-0 pl-md-0">
            <label
              class="mt-0 mt-md-3 text-dark mt-3 mt-md-0 col-form-label font-size-18 d-inline-block"
              >{{ "manufacturer reference" | translate }}</label
            >
            <input
              type="text"
              class="form-control border border-primary border border-light text-dark"
              placeholder="{{ 'enter' | translate }} {{
                'manufacturer reference' | translate
              }}"
              formControlName="manufacturer_reference"
            />
          </div>
        </div>
      </div>

      <div class="form-group w-100 float-left mb-0 mt-3">
        <div class="row pl-3">
          <div class="col-lg-6 col-sm-12 d-flex pl-0 pl-md-0">
            <label
              class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-dark col-form-label font-size-18"
              >{{ "country" | translate }}</label
            >

            <select
              id="language_id"
              class="form-control border border-primary border border-light text-dark"
              formControlName="country_id"
            >
              <option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
              </option>
            </select>
            <span
              class="q-mark text-light border border-light d-inline-block m-3 rounded-circle text-center pr-2 pr-md-2 pl-2 pl-md-2 position-relative"
              >?</span
            >
          </div>
          <div class="col-lg-6 col-sm-12 d-flex mt-4 mt-lg-0 pl-0 pl-md-0">
            <label
              class="mt-0 mt-md-3 text-dark mt-3 mt-md-0 col-form-label d-inline-block font-size-18"
              >ASIN</label
            >
            <input
              type="text"
              class="form-control border border-primary border border-light text-dark"
              placeholder="{{ 'enter' | translate }} ASIN"
              formControlName="asin"
              maxlength="255"
            />
          </div>
        </div>
      </div>
      <!-- New changes-->
      <div class="form-group w-100 float-left mb-0 mt-3">
        <div class="row pl-3">
          <div class="col-lg-6 col-sm-12 d-flex pl-0 pl-md-0">
            <label
              class="col-6 col-md-0 col-lg-3 mt-0 mt-md-1 text-secondary ellipses col-form-label"
              >{{ "manufacturer" | translate }} {{ "name" | translate }}</label
            >
            <input
              formControlName="manufacturer_name"
              placeholder=" {{ 'enter' | translate }} {{
                'manufacturer' | translate
              }} {{ 'name' | translate }}"
              type="text"
              class="form-control border border-light"
              id="manufacturer_name"
              [readonly]="isEnable"
              [ngClass]="{ 'disble-box': isEnable }"
            />
            <span
              class="text-danger pt-1"
              *ngIf="
                formControl('detailsForm').manufacturer_name.touched &&
                formControl('detailsForm').manufacturer_name.errors?.required
              "
            >
              <small
                >{{ "manufacturer" | translate }} {{ "name" | translate }}
                {{ "is required." | translate }}
              </small>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="driv-part mt-5">
  <h2 class="font-weight-bold mb-3 pl-4 text-dark font-size-18">
    {{ "characteristics" | translate }}
  </h2>
  <div class="driv-tl p-3 rounded border border-primary">
    <div class="responsive">
      <table class="table table-bordered border border-primary rounded">
        <thead>
          <tr>
            <th>{{ "category" | translate }}</th>
            <th>{{ "description" | translate }}</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let char of caracteristics; let i = index">
            <td>{{ char.caracteristique }}</td>
            <td>{{ char.description }}</td>

            <td>
              <a class="edit-pen cursor-pointer" (click)="editChar(i)"
                ><img src="../../../assets/images/edit-icon.png"
              /></a>
              <a (click)="removeChar(i)" class="cursor-pointer"
                ><img src="../../../assets/images/delete-icon.png"
              /></a>
            </td>
          </tr>
          <tr *ngIf="caracteristics.length === 0">
            <td class="border-0">
              {{ "no" | translate }} {{ "characteristics" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <form class="driv-form">
      <div class="form-horizontal">
        <div class="form-group mb-0 mt-2">
          <div class="row pl-2">
            <label
              class="col-sm-2 mt-0 mt-md-2 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
              >{{ "category" | translate }}</label
            >
            <div class="col-lg-5">
              <input
                type="text"
                class="form-control text-dark border border-primary"
                placeholder="{{ 'enter' | translate }} {{
                  'category' | translate
                }}"
                [(ngModel)]="activeDocs.char.caracteristique"
                id="activeDocs?.char?.caracteristique"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
        </div>

        <div class="form-group mt-4 mb-0">
          <div class="row pl-2">
            <label
              class="col-sm-2 mt-0 mt-md-2 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
              >{{ "description" | translate }}</label
            >
            <div class="col-lg-5">
              <input
                type="text"
                class="form-control text-dark border border-primary"
                placeholder="{{ 'enter' | translate }} {{
                  'description' | translate
                }}"
                [(ngModel)]="activeDocs.char.description"
                id="activeDocs?.char?.description"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>

            <div
              class="col-sm-12 col-lg-5 d-flex mt-0 mt-sm-2 justify-content-end mt-2 mt-lg-0"
            >
              <div class="file-add-nt">
                <button
                  class="btn btn-primary mt-3 mt-md-0"
                  [disabled]="!activeDocs.char.caracteristique"
                  (click)="addChars()"
                >
                  {{ (activeEdit >= 0 ? "save" : "add") | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="film-part mt-4">
  <h2 class="font-weight-bold pl-3 text-dark mb-3 font-size-18">Images</h2>
  <div class="film-box rounded p-4 border border-primary">
    <div
      class="img-pt rounded text-center d-inline-block border border-primary mb-3"
      *ngFor="
        let file of files | filter : { type_id: FileType.image };
        let i = index
      "
    >
      <img [src]="file.url ? file.url : file.buffer_url" class="img-wrapper" />
      <span
        ><a (click)="removeFile(file)" class="cursor-pointer"
          ><img src="../../../assets/images/close-icon.png" /></a
      ></span>
    </div>
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-end">
        <button
          class="bg-primary rounded border font-weight-bold text-white font-size-18 pt-3 pb-3 pl-5 pr-5"
          (click)="addImageInput.click()"
        >
          {{ "add" | translate }}
        </button>
        <input
          type="file"
          name="addImage"
          accept="image/*"
          class="d-none"
          #addImageInput
          (change)="selectFile($event, 'image')"
        />
      </div>
    </div>
  </div>
</div>
<div class="film-part mt-4">
  <h2 class="font-weight-bold text-dark pl-3 mb-3 font-size-18">
    {{ "videos" | translate }}
  </h2>
  <div class="film-box rounded p-4 border border-primary">
    <div
      class="img-pt rounded text-center d-inline-block border border-primary"
      *ngFor="
        let file of files | filter : { type_id: FileType.video };
        let i = index
      "
    >
      <video
        *ngIf="!file.external_url"
        width="320"
        height="240"
        controls
        class="img-wrapper"
      >
        <source [src]="file.url ? file.url : file.buffer_url" />
      </video>
      <iframe
        *ngIf="file.external_url"
        [src]="file.external_url"
        width="120"
        height="120"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
      <span
        ><a (click)="removeFile(file)"
          ><img src="../../../assets/images/close-icon.png" /></a
      ></span>
    </div>
    <form class="driv-form">
      <div class="form-group d-inline-block w-100">
        <div class="row">
          <span class="col-lg-8">
            <input
              type="text"
              [(ngModel)]="urlInput"
              id="urlInput"
              [ngModelOptions]="{ standalone: true }"
              class="form-control border border-light d-flex rounded p-2"
              placeholder="{{ 'add youtube/vimeo link' | translate }} "
            />
          </span>
          <span class="col-lg-4">
            <span class="file-add-nt d-inline-block w-100 position-relative">
              <button
                [disabled]="!urlInput"
                class="bg-primary rounded font-weight-bold text-white font-size-18 float-right position-relative"
                (click)="addExternalUrl('video')"
              >
                {{ "submit" | translate }}
              </button>
            </span>
          </span>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-end">
        <button
          class="bg-primary border rounded font-weight-bold text-white font-size-18 pl-5 pr-5 pt-3 pb-3"
          (click)="addVideoInput.click()"
        >
          {{ "add" | translate }}
        </button>
        <input
          type="file"
          name="addVideo"
          accept="video/*"
          class="d-none"
          #addVideoInput
          (change)="selectFile($event, 'video')"
        />
      </div>
    </div>
  </div>

  <div class="driv-part mt-5">
    <h2 class="font-weight-bold mb-3 text-dark pl-4 font-size-18">Drivers</h2>
    <div class="driv-tl p-3 rounded border border-primary">
      <div class="responsive">
        <table class="table table-bordered border border-primary rounded">
          <thead>
            <tr>
              <th>{{ "description" | translate }}</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let file of files | filter : { type_id: FileType.driver };
                let i = index
              "
            >
              <td>{{ file.description }}</td>
              <td>
                <a
                  class="edit-pen cursor-pointer"
                  [href]="file?.url ? file?.url : file?.buffer_url"
                  download=""
                  target="blank"
                  ><img src="../../../assets/images/download.png"
                /></a>
                <a (click)="removeFile(file)" class="cursor-pointer"
                  ><img src="../../../assets/images/delete-icon.png"
                /></a>
              </td>
            </tr>
            <tr
              *ngIf="
                (files | filter : { type_id: FileType.driver }).length === 0
              "
            >
              <td class="border-0">{{ "no" | translate }} Drivers</td>
            </tr>
          </tbody>
        </table>
      </div>

      <form class="driv-form">
        <div class="form-horizontal">
          <div class="form-group mb-0 mt-2">
            <div class="row pl-1">
              <label
                class="col-sm-2 mt-0 mt-md-2 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "description" | translate }}</label
              >
              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control border border-primary text-dark"
                  [(ngModel)]="activeDocs.driver.description"
                  #nameForDriver="ngModel"
                  id="nameForDriver"
                  [ngClass]="{
                    warn: !nameForDriver.value && nameForDriver.touched
                  }"
                  name="activeDocsdriver.description"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholder]="
                    (!nameForDriver.value && nameForDriver.touched
                      ? 'description is required.'
                      : 'enter description'
                    ) | translate
                  "
                />
              </div>
            </div>
          </div>

          <div class="form-group mt-4 mb-0">
            <div class="row pl-1">
              <label
                class="col-sm-2 mt-0 mt-md-3 col-form-label ml-2 ml-md-0 text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "path" | translate }}</label
              >

              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control border border-primary text-dark"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="activeDocs.driver.name"
                  [(ngModel)]="activeDocs.driver.name"
                />
              </div>

              <div
                class="col-sm-12 col-lg-5 d-flex mt-0 mt-sm-2 justify-content-end mt-2 mt-lg-0"
              >
                <input
                  type="file"
                  class="d-none"
                  #driverinput
                  (change)="selectFile($event, 'driver')"
                />
                <button
                  class="font-size-18 text-dark color-bg rounded border border-primary font-weight-bold mr-3 col-lg-4"
                  (click)="driverinput.click()"
                >
                  {{ "choose file" | translate }}
                </button>

                <button
                  (click)="uploadDoc('driver')"
                  [disabled]="
                    !activeDocs.driver.file || !activeDocs.driver.description
                  "
                  class="btn btn-primary rounded font-weight-bold font-size-18 pb-3 pt-3 col-lg-4"
                >
                  {{ "upload" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="driv-part mt-5">
    <h2 class="font-weight-bold text-dark mb-3 pl-4 font-size-18">Documents</h2>
    <div class="driv-tl p-3 rounded border border-primary">
      <div class="responsive">
        <table class="table table-bordered border border-primary rounded">
          <thead>
            <tr>
              <th>{{ "description" | translate }}</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let file of files | filter : { type_id: FileType.document };
                let i = index
              "
            >
              <td>{{ file.description }}</td>
              <td>
                <a
                  class="edit-pen cursor-pointer"
                  [href]="file?.url ? file?.url : file?.buffer_url"
                  download=""
                  target="blank"
                  ><img src="../../../assets/images/download.png"
                /></a>
                <a (click)="removeFile(file)" class="cursor-pointer"
                  ><img src="../../../assets/images/delete-icon.png"
                /></a>
              </td>
            </tr>
            <tr
              *ngIf="
                (files | filter : { type_id: FileType.document }).length === 0
              "
            >
              <td class="border-0">{{ "no" | translate }} Documents</td>
            </tr>
          </tbody>
        </table>
      </div>

      <form class="driv-form">
        <div class="form-horizontal">
          <div class="form-group mb-0 mt-4">
            <div class="row pl-1">
              <label
                class="col-sm-2 mt-0 mt-md-2 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "description" | translate }}</label
              >
              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control border border-primary text-dark"
                  [(ngModel)]="activeDocs.document.description"
                  id="nameForDocu"
                  [ngClass]="{
                    warn: !nameForDocu.value && nameForDocu.touched
                  }"
                  [ngModelOptions]="{ standalone: true }"
                  #nameForDocu="ngModel"
                  [placeholder]="
                    (!nameForDocu.value && nameForDocu.touched
                      ? 'description is required.'
                      : 'enter description'
                    ) | translate
                  "
                />
              </div>
            </div>
          </div>

          <div class="form-group mb-0 mt-4">
            <div class="row pl-1">
              <label
                class="col-sm-2 mt-0 mt-md-3 col-form-label ml-2 ml-md-0 text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "path" | translate }}</label
              >

              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control border border-primary text-dark"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="activeDocs.document.name"
                  [(ngModel)]="activeDocs.document.name"
                />
              </div>

              <div
                class="col-sm-12 col-lg-5 d-flex mt-0 mt-sm-2 justify-content-end mt-2 mt-lg-0"
              >
                <input
                  type="file"
                  class="d-none"
                  #documentinput
                  (change)="selectFile($event, 'document')"
                />
                <button
                  class="font-size-18 text-dark color-bg rounded border border-primary font-weight-bold mr-3 col-lg-4"
                  (click)="documentinput.click()"
                >
                  {{ "choose file" | translate }}
                </button>

                <button
                  (click)="uploadDoc('document')"
                  [disabled]="
                    !activeDocs.document.file ||
                    !activeDocs.document.description
                  "
                  class="btn btn-primary rounded font-weight-bold font-size-18 pb-3 pt-3 col-lg-4"
                >
                  {{ "upload" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="driv-part mt-5">
    <h2 class="font-weight-bold mb-3 pl-4 text-dark font-size-18">
      {{ "links" | translate }}
    </h2>
    <div class="driv-tl p-3 rounded border border-primary">
      <div class="responsive">
        <table class="table table-bordered border border-primary rounded">
          <thead>
            <tr>
              <th>{{ "seller name" | translate }}</th>
              <th>{{ "seller image" | translate }}</th>
              <th>{{ "links" | translate }}</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let li of links; let i = index">
              <td>{{ li?.name }}</td>
              <td>{{ li?.url }}</td>
              <td>{{ li?.link }}</td>
              <td>
                <a class="edit-pen cursor-pointer" (click)="editLink(i)"
                  ><img src="../../../assets/images/edit-icon.png"
                /></a>
                <a (click)="removeLink(i)" class="cursor-pointer"
                  ><img src="../../../assets/images/delete-icon.png"
                /></a>
              </td>
            </tr>
            <tr *ngIf="links?.length === 0">
              <td class="border-0">
                {{ "no" | translate }} {{ "link" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <form [formGroup]="linkForm" class="driv-form">
        <div class="form-horizontal">
          <div class="form-group mb-0 mt-2">
            <div class="row pl-2">
              <label
                class="col-sm-2 mt-0 mt-md-3 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "seller name" | translate }}</label
              >
              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control border border-primary text-dark"
                  formControlName="sellerName"
                  [(ngModel)]="activeDocs.linkDetail.name"
                  id="activeDocs?.linkDetail?.name"
                  placeholder="{{ 'enter' | translate }} {{
                    'seller name' | translate
                  }}"
                />
                <div
                  class="text-danger pt-1"
                  *ngIf="
                    formControl('linkForm').sellerName?.touched &&
                    formControl('linkForm').sellerName.errors?.required
                  "
                >
                  <small
                    >{{ "seller name" | translate }}
                    {{ "is required." | translate }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-0 mt-4">
            <div class="row pl-2">
              <label
                class="col-sm-2 mt-0 mt-md-3 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "seller image" | translate }}</label
              >
              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control border border-primary text-dark"
                  formControlName="imageUrl"
                  [(ngModel)]="activeDocs.linkDetail.url"
                  id="activeDocs?.linkDetail?.url"
                  placeholder="{{ 'enter' | translate }} {{
                    'seller image' | translate
                  }} URL"
                />
                <div
                  class="text-danger pt-1"
                  *ngIf="
                    formControl('linkForm').imageUrl?.touched &&
                    formControl('linkForm').imageUrl.errors?.required
                  "
                >
                  <small
                    >{{ "seller image" | translate }} url
                    {{ "is required." | translate }}
                  </small>
                </div>
                <div
                  class="text-danger pt-1"
                  *ngIf="
                    formControl('linkForm').imageUrl?.touched &&
                    formControl('linkForm').imageUrl.errors?.pattern
                  "
                >
                  <small>{{ "invalid" | translate }} URL. </small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-0 mt-4">
            <div class="row pl-2">
              <label
                class="col-sm-2 mt-0 mt-md-3 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "link" | translate }} URL</label
              >
              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control text-dark border border-primary"
                  placeholder="{{ 'enter' | translate }} {{
                    'link' | translate
                  }}"
                  [(ngModel)]="activeDocs.linkDetail.link"
                  id="activeDocs?.linkDetail?.link"
                  formControlName="linkUrl"
                />
                <div
                  class="text-danger pt-1"
                  *ngIf="
                    formControl('linkForm').linkUrl?.touched &&
                    formControl('linkForm').linkUrl.errors?.required
                  "
                >
                  <small
                    >{{ "link" | translate }} URL
                    {{ "is required." | translate }}</small
                  >
                </div>
                <div
                  class="text-danger pt-1"
                  *ngIf="
                    formControl('linkForm').linkUrl?.touched &&
                    formControl('linkForm').linkUrl.errors?.pattern
                  "
                >
                  <small>{{ "invalid" | translate }} URL. </small>
                </div>
              </div>

              <div class="col-lg-5 file-add-nt position-relative">
                <button
                  class="btn btn-primary mt-3 mt-md-0 float-right position-relative"
                  [disabled]="!linkForm.valid"
                  (click)="addLinks()"
                >
                  {{ (activeEdit >= 0 ? "save" : "add") | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="driv-part mt-5">
    <h2 class="font-weight-bold mb-3 pl-4 text-dark font-size-18">
      {{ "Tags" | translate }}
    </h2>
    <div class="driv-tl p-3 rounded border border-primary">
      <div class="responsive">
        <table class="table table-bordered border border-primary rounded">
          <thead>
            <tr>
              <th>{{ "Tag" | translate }}</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tag of tags; let i = index">
              <td>{{ tag.tag_name }}</td>
              <td>
                <a class="edit-pen cursor-pointer" (click)="editTag(i)"
                  ><img src="../../../assets/images/edit-icon.png"
                /></a>
                <a (click)="removeTag(i)" class="cursor-pointer">
                  <img src="../../../assets/images/delete-icon.png"
                /></a>
              </td>
            </tr>
            <tr *ngIf="tags.length === 0">
              <td class="border-0">
                {{ "no" | translate }} {{ "Tags" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <form class="driv-form" [formGroup]="tagForm">
        <div class="form-horizontal">
          <div class="form-group mt-2 mb-0">
            <div class="row pl-2">
              <label
                class="col-sm-2 mt-0 mt-md-3 ml-2 ml-md-0 col-form-label text-dark font-size-18 w-100 pl-2 pl-md-4 pb-1"
                >{{ "Tag" | translate }}</label
              >
              <div class="col-lg-5">
                <input
                  type="text"
                  class="form-control text-dark border border-primary"
                  placeholder="{{ 'enter' | translate }} {{
                    'Tag' | translate
                  }}"
                  formControlName="tagName"
                />
              </div>
              <div
                class="col-sm-12 col-lg-5 d-flex mt-0 mt-sm-2 justify-content-end mt-2 mt-lg-0"
              >
                <div class="file-add-nt">
                  <button
                    class="btn btn-primary mt-3 mt-md-0"
                    [disabled]="tagForm.invalid"
                    (click)="addTag()"
                  >
                    {{ (activeTagEdit >= 0 ? "save" : "add") | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="driv-part btn-div mt-5">
    <div class="driv-tl p-0 border-0">
      <form class="driv-form">
        <div class="form-horizontal">
          <div class="form-group">
            <div class="mt-3 d-flex justify-content-end">
              <button
                type="button"
                class="font-weight-bold font-size-18 rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
                (click)="doCancel()"
              >
                {{ "cancel" | translate }}
              </button>
              <button
                type="button"
                class="save-product-btn font-size-18 border rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
                [disabled]="!detailsForm.valid"
                (click)="addProduct()"
              >
                {{ "save" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
