<div role="document">
    <div class="modal-content">

        <!-- modal header -->
        <div class="modal-header bg-primary ">
            <h2 class="modal-title text-white"> {{'manufacturer' | translate}} {{'data' | translate}}</h2>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button> -->
        </div>
        <!-- modal header -->
        <div class="modal-body">
            <!-- modal content -->
            <form [formGroup]="regionalData">
                <div class="row justify-content-center ">
                    <div class="col-md-6 width-35">

                        <div class="addImg-part">

                            <div class="addImg-box mt-2">

                                <div class="img-pt d-flex" *ngIf="photoURL">

                                    <img *ngIf="photoURL" [src]="photoURL" class="img-wrapper">
                                    <span class="cross-img"><a><img src="../../../assets/images/close-icon.png"
                                                (click)="photoURL = null && photo = null"></a></span>
                                </div>

                                <div class="img-add" *ngIf="!photoURL">

                                    <button class="btn btn-primary" value="file" (click)="fileInput.click()">
                                        {{'add' | translate}} Image
                                    </button>

                                    <input type="file" name="addImage" accept="image/*" #fileInput
                                        (change)="selectImage($event, 'image')" />
                                    <span class="text-danger pt-1 photo-error" *ngIf="photoError">
                                        <small>{{photoError}}</small>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <label for="language" class="col-form-label pb-0 mt-5"><small>{{'select' | translate}}
                                {{'language' | translate}}: </small></label>
                        <select #language class="browser-default custom-select" formControlName="language_id">
                            <option *ngFor="let lan of languages; let i = index" [value]="lan.id">{{lan.name}}</option>
                        </select>
                        <span class="text-danger pt-1"
                            *ngIf="(formControl.language_id.touched) && formControl.language_id.errors?.required">
                            <small>{{'language' | translate}} {{'is required.' | translate}}</small>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="country" class="col-form-label pb-0 "><small>{{'select' | translate}}
                                {{'country' | translate}}:</small></label>
                        <select #country class="browser-default custom-select" formControlName="country_id">
                            <option *ngFor="let cn of countries; let i = index" [value]="cn.id">{{cn.name}}</option>
                        </select>
                        <span class="text-danger pt-1"
                            *ngIf="(formControl.country_id.touched) && formControl.country_id.errors?.required">
                            <small>{{'country' | translate}} {{'is required.' | translate}} </small>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="url" class="col-form-label pb-0 "><small>URL: </small></label>
                        <input type="text" id="url" formControlName="url" class="form-control"
                            placeholder=" {{'enter' | translate}} URL" />
                        <span class="text-danger pt-1"
                            *ngIf="(formControl.url.touched) && formControl.url.errors?.pattern">
                            <small>{{'invalid' | translate}} URL</small>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="description" class="col-form-label pb-0 "><small>{{'description' | translate}}:
                            </small></label>
                        <textarea type="text" id="description" formControlName="description" class="form-control"
                            placeholder="{{'enter' | translate}} {{'description' | translate}}"></textarea>
                    </div>
                </div>
                 <div class="row">
                    <div class="col-md-12">
                        <label for="description" class="col-form-label pb-0 "><small>{{'description' | translate}}:
                            </small></label>
                        <angular-editor  [config]="editorConfig" formControlName="html_description"></angular-editor>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="contact_number" class="col-form-label pb-0 "><small>{{'phone number' | translate}}:
                            </small></label>
                        <input type="text" id="contact_number" formControlName="contact_number" class="form-control"
                            placeholder=" {{'enter' | translate}}  {{'phone number' | translate}}" />
                        <span class="text-danger pt-1"
                            *ngIf="(formControl.contact_number.touched) && formControl.contact_number.errors?.pattern">
                            <small>{{'invalid' | translate}} {{'contact number' | translate}}.</small>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="email" class="col-form-label pb-0 "><small>Email: </small></label>
                        <input type="email" id="email" formControlName="email" class="form-control"
                            placeholder="{{'enter' | translate}} Email" />
                        <span class="text-danger pt-1"
                            *ngIf="(formControl.email.touched) && formControl.email.errors?.email">
                            <small>{{'invalid' | translate}} Email</small>
                        </span>
                    </div>
                </div>
            </form>
            <!-- modal content -->

        </div>
        <div class="modal-footer d-flex justify-content-between">


            <button type="button" class="btn btn-primary"
                (click)="activeModal.close(false)">{{'cancel' | translate}}</button>
            <button type="button" class="btn btn-primary" (click)="saveRegionalData()"
                [disabled]="!regionalData.valid">{{'save' | translate}}</button>


        </div>
    </div>
</div>