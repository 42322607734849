<div class="row page_wrapper ml-0">
  <div class=" col-md-6 text-center pr-0 pl-0 d-none d-md-inline-block">
    <div class="w-100 h-100">
      <img src="../../../assets/images/signup-img.png" class="d-md-block d-none wh-inherit">
    </div>
    <div class="d-flex justify-content-center">
      <div class="position-absolute d-md-block d-none bg-blur ">
        <img src="../../../assets/images/logo1.png" class="mt-5">
        <div class="border-line border-top position-absolute">
          <img src="../../../assets/images/hub.svg" class="mt-4">
        </div>
      </div>
    </div>
    <div class="position-relative bottom-55">
      <!-- <ngb-carousel>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p *ngIf="langSelected=='en'" class="text-justify font-size-14 font-weight-bold">Hub is
              the Describy plateform made for Manufacturers.</p>
            <p *ngIf="langSelected=='fr'" class="text-justify font-size-14 font-weight-bold"> Hub
              est la plateforme créé par Describy pour les Fabricants.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p *ngIf="langSelected=='en'" class="text-justify font-size-14 font-weight-bold">Manufacturers can manage
              data (description, characteristics, images, videos...) of their product portfolio.</p>
            <p *ngIf="langSelected=='fr'" class="text-justify font-size-14 font-weight-bold"> Les Fabricants peuvent
              gérer l'ensemble de l'information autour de leur produits (description, caracteristiques, images,
              vidéos...)</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p *ngIf="langSelected=='en'" class="text-justify font-size-14 font-weight-bold">Sign up and get 3 month
              free trial.</p>
            <p *ngIf="langSelected=='fr'" class="text-justify font-size-14 font-weight-bold"> Inscrivez vous, les 3
              premiers mois sont offerts.</p>
          </div>
        </ng-template>
      </ngb-carousel> -->
      <ul class="lang-switcher position-absolute m-0 p-0 font-size-14 text-change">
        <li class=" d-inline-block p-2 font-size-14" [class.active]="langSelected=='fr'"> <a
            class="text-white  font-size-14 font-weight-400 d-block cursor-pointer bg-wh"
            (click)="languageSelected('fr')">FR</a>
        </li>
        <li class="vertical-row position-absolute d-inline-block"></li>
        <li class="d-inline-block rounded-circle p-2  font-size-14" [class.active]="langSelected=='en'"><a
            class="text-white font-size-14 font-weight-400 d-block cursor-pointer bg-wh"
            (click)="languageSelected('en')">EN</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-6 pl-0 pr-0 my-auto scrollbar overflow-auto">
    <div class="text-center mx-auto mt-md-4 mt-0 col-12 col-sm-10 col-md-12 col-lg-10">
      <img src="https://describy.com/assets/images/logo.svg">
      <h4 *ngIf="langSelected=='en'" class="font-weight-bold mt-3">SIGN UP FOR AN ACCOUNT</h4>
      <h4 *ngIf="langSelected=='fr'" class="font-weight-bold mt-3">INSCRIVEZ-VOUS POUR UN COMPTE</h4>

      <form class="pl-md-5 pr-md-5 pl-0 pr-0 mt-3" [formGroup]="signUpForm">
        <div class="form-group mb-1">
          <input type="text" class="form-control p-4 border-color placeholder-color" placeholder="First Name"
            id="first_name" formControlName="first_name">
          <div class="text-danger text-left"
            *ngIf="formControl.first_name.touched && formControl.first_name.errors?.required">
            <small *ngIf="langSelected=='en'">
              First Name is required.
            </small>
            <small *ngIf="langSelected=='fr'">
              Le prénom est requis.
            </small>
          </div>
        </div>
        <div class="form-group mb-1 ">
          <input type="text" class="form-control p-4 border-color placeholder-color" placeholder="Last Name"
            id="last_name" formControlName="last_name">
          <div class="text-danger text-left"
            *ngIf="formControl.last_name.touched && formControl.last_name.errors?.required">
            <small *ngIf="langSelected=='en'">
              Last Name is required.
            </small>
            <small *ngIf="langSelected=='fr'">
              Le nom de famille est requis.
            </small>
          </div>
        </div>
        <div class="form-group mb-1">
          <input type="text" class="form-control p-4 border-color placeholder-color" placeholder="Manufacturer Name"
            id="manufacturer_name" formControlName="manufacturer_name">
          <div class="text-danger text-left"
            *ngIf="formControl.manufacturer_name.touched && formControl.manufacturer_name.errors?.required">
            <small *ngIf="langSelected=='en'">
              Manufacturer Name is required.
            </small>
            <small *ngIf="langSelected=='fr'">
              Le nom du fabricant est requis.
            </small>
          </div>
        </div>
        <div class="form-group mb-1">
          <input type="text" class="form-control p-4 border-color placeholder-color" placeholder="Manufacturer website"
            id="url" formControlName="url">
          <div class="text-danger text-left "
            *ngIf="formControl.url.touched && formControl.url.errors?.required">
            <small *ngIf="langSelected=='en'">
              Manufacturer Website is required.
            </small>
            <small *ngIf="langSelected=='fr'">
              Le site Web du fabricant est requis.
            </small>
          </div>
          <div class="text-danger text-left "
          *ngIf="formControl.url.touched && formControl.url.errors?.pattern">
          <small *ngIf="langSelected=='en'">
            Invalid Website URL
          </small>
          <small *ngIf="langSelected=='fr'">
            URL de site Web non valide.
          </small>
        </div>
        </div>
        <div class="form-group mb-1">
          <input type="text" class="form-control p-4 border-color placeholder-color"
            placeholder="Professional mail address" id="email" formControlName="email">
          <div class="text-danger text-left " *ngIf="formControl.email.touched && formControl.email.errors?.required">
            <small *ngIf="langSelected=='en'">
              Email is required.
            </small>
            <small *ngIf="langSelected=='fr'">
              L'e-mail est requis
            </small>
          </div>
          <div class="text-danger text-left " *ngIf="formControl.email.touched && formControl.email.errors?.email">
            <small *ngIf="langSelected=='en'">
              Invalid Email.
            </small>
            <small *ngIf="langSelected=='fr'">
              Email invalide.
            </small>
          </div>
        </div>

        <div class="form-group mb-1">
          <input type="text" class="form-control p-4 border-color placeholder-color" placeholder="Phone Number"
            id="contact_number" formControlName="contact_number">
          <div class="text-danger text-left"
            *ngIf="formControl.contact_number.touched && formControl.contact_number.errors?.pattern">
            <small *ngIf="langSelected=='en'">
              Invalid Phone number.
            </small>
            <small *ngIf="langSelected=='fr'">
              Numéro de téléphone invalide.
            </small>
          </div>
        </div>

        <!-- <div class="form-group mb-2">

          <input [type]="isMutePass?'text':'password'" class="form-control p-4 border-color placeholder-color"
            id="password" formControlName="password" placeholder="Password">
          <span class="p-viewer float-right mr-3 position-relative">
            <i class="fa  eye-color cursor-pointer" [ngClass]="{'fa-eye': isMutePass, 'fa-eye-slash': !isMutePass }"
              (click)="isMutePass = !isMutePass"></i>
          </span>
          <div class="text-danger text-left "
            *ngIf="formControl.password.touched && formControl.password.errors?.required">
            <small *ngIf="langSelected=='en'">
              Password is required.
            </small>
            <small *ngIf="langSelected=='fr'">
              Mot de passe requis.
            </small>

          </div>
          <div class="text-danger text-left "
            *ngIf="formControl.password.touched && formControl.password.errors?.pattern">
            <small *ngIf="langSelected=='en'">
              Password should be atleast 8 characters, should contain one uppercase letter, one lowercase letter,
              one
              special character and one number.
            </small>

            <small *ngIf="langSelected=='fr'">
              Le mot de passe doit comporter au moins 8 caractères, doit contenir une lettre majuscule, une lettre
              minuscule, un caractère spécial et un chiffre.
            </small>
          </div>
        </div> -->


        <p *ngIf="langSelected=='en'" class="text-left font-weight-bold font-size-12 text-light-brown">An email with
          instructions will be sent</p>
        <p *ngIf="langSelected=='fr'" class="text-left font-weight-bold font-size-12 text-light-brown">Un e-mail avec
          des instructions sera envoyé</p>


        <div>
          <button *ngIf="langSelected=='en'" type="button"
            class="btn btn-bg text-white p-3 font-size-14 font-weight-bold w-100" (click)="signUpNewUser()"
            [disabled]=" !signUpForm.valid">SEND</button>
          <button *ngIf="langSelected=='fr'" type="button"
            class="btn btn-bg text-white p-3 font-size-14 font-weight-bold w-100" (click)="signUpNewUser()"
            [disabled]=" !signUpForm.valid">NVOYER</button>

        </div>
        <div class="d-flex mt-1">
          <div class="text-left pl-0">
            <p *ngIf="langSelected=='en'" class="text-orange font-size-12 font-weight-bold cursor-pointer"
              routerLink="/login" routerLinkActive="active">Already have an account ? Sign in !</p>

            <p *ngIf="langSelected=='fr'" class="text-orange font-size-12 font-weight-bold cursor-pointer"
              routerLink="/login" routerLinkActive="active">Vous avez déjà un compte ? S'identifier !</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>