import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from '@ngx-translate/core';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() public message;
  @Input() public showCancel;

  constructor(public activeModal: NgbActiveModal,
              private managerService: ManagerService,
              private translate: TranslateService) { }

  ngOnInit(): void {

    this.managerService.switchLn.subscribe((ln: any) => {
      console.log('inside ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })
  }

}
