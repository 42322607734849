import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ALLOWED_ROLE } from '../../common/constants';
import { isAuthenticated } from './auth.service';
import { ManagerService } from '../manager.service';
import { setInStorage } from '../../common/utils'; 

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {
  constructor(   private router: Router,private managerService: ManagerService){

  }
  canActivate = async (next: ActivatedRouteSnapshot,state: RouterStateSnapshot) => {
    const checkAuthentication = await isAuthenticated();
    if (checkAuthentication) {
        const result = await this.managerService.loadUser();
        if (result && result.role && ALLOWED_ROLE.includes(result.role.id)) {
          setInStorage('user', result);
          this.router.navigate(["/manager/dashboard"]);
          return false;
        }
    }
    return true;
  }
}
