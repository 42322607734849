import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ManagerService } from 'src/app/services/manager.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-add-files',
  templateUrl: './add-files.component.html',
  styleUrls: ['./add-files.component.scss']
})
export class AddFilesComponent implements OnInit {
  @Input() public data = [];
  @Input() public title;
  public pageCount: number = 3;
  public drivers = [];
  private currentIndex: number = 0;
  constructor(public activeModal: NgbActiveModal,
              private managerService: ManagerService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    console.log(this.data);
    if(this.data.length > 0) this.data.map(x => x.selected = false);
    this.onScroll();

    this.managerService.switchLn.subscribe((ln: any) => {
      console.log('inside ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })

  }

  onScroll = () => {
    console.log('scrolled', this.currentIndex);
    this.drivers = [...this.drivers, ...this.data.slice(this.currentIndex*this.pageCount, (this.currentIndex + 1 )*this.pageCount)];
    this.currentIndex += 1;
  }

  saveData = () => {
    let result = this.data.filter(x=> x.selected);
    let response = result.map(x => {
      let {selected, product_id, id,file_type, ...rest} = x;
      return rest;
    });
    let res = {
      success: true,
      data: response
    };
    this.activeModal.close(res);
  }

}
