// core modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// project modules
import { PaginationComponent } from './components/pagination/pagination.component';
import { MonthsPipe } from './pages/billing/pipes/months.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [MonthsPipe, PaginationComponent],
  exports: [PaginationComponent, MonthsPipe],
  providers:[MonthsPipe]
})
export class SharedModule {}