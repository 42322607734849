import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ManagerService } from "../manager.service";
import { isAuthenticated } from "./auth.service";
import { ALLOWED_ROLE } from '../../common/constants';
import { setInStorage } from '../../common/utils'; 
@Injectable()
export class AuthGuard  {

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private managerService: ManagerService
    ) { }

    canActivate = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        let { url } = state;
        const checkAuthentication = await isAuthenticated();
        // Error 1
        if (!checkAuthentication) {
            this.toastr.error("You are not logged in. Please login to continue", "Login Error!");
            this.router.navigate(["/login"]);
            return false;
        }
        if (checkAuthentication){
            let result = await this.managerService.loadUser();
            if (result && result.role && ALLOWED_ROLE.includes(result.role.id)) {
              setInStorage('user', result);
            }
            return true;
        }
        return false;

    };


}
