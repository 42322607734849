import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { ManagerLayoutComponent } from './layouts/manager-layout/manager-layout.component';
import { AuthGuard } from "./services/auth/auth.guard";
import { LoginGuard } from './services/auth/login.guard';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: AuthLayoutComponent,
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
        canActivate: [LoginGuard],
      },
      {
        path: "manager",
        component: ManagerLayoutComponent,
        loadChildren: () => import('./layouts/manager-layout/manager-layout.module').then(m => m.ManagerLayoutModule),
        canActivate: [AuthGuard]
      }
    ],
  },
  {path: "**", redirectTo : "login"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
