// core modules
const util = require('util');
import { Injectable } from "@angular/core";
// project modules
import { URLS } from './../common/constants';
import { call, uploadFileToS3 } from './backend.service';

@Injectable({
    providedIn: "root",
  })
export class ProductService{
    getProducts = async (page, count, ean = null) => {
        let queryParams = {
            page: page,
            limit: count
        }
        if (ean) queryParams['ean'] = ean;
        let result = await call(URLS.GET_PRODUCTS, 'get', null, queryParams);
        if (result.success) {
            return result;
        }
    }

    getMetaData = async (type) => {
        const url = util.format(URLS.GET_METADATA, type);
        let { success, results } = await call(url, 'get');
        if (success) {
            return results;
        }
    }

    updateProduct = async (params) => {
        return await call(URLS.UPDATE_PRODUCT, 'put', params);
    }

    updateFile = async (params) => {
        return await call(URLS.UPDATE_FILE, 'put', params);
    }

    uploadFile = async (url, file) => {
        return await uploadFileToS3(url, file);
    }

    getProductById = async (id) => {
        const url = util.format(URLS.GET_PRODUCT_BY_ID, id);
        let { success, result } = await call(url, 'get');
        if (success) return result;
    }

    getPublicProducts = async(queryParams) => {
        let { results, success } = await call(URLS.PUBLC_PRODUCTS, 'get', null, queryParams);
        if(success) return results;
    }
}

