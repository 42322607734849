import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ALLOWED_ROLE, ERROR_MESSAGES, SUCCESS_MESSAGES, VALIDATION_REGEX, DEFAULT_LANGUAGE_TYPE, FRENCH_LANGUAGE } from 'src/app/common/constants';
import { ConfirmedValidator } from 'src/app/common/customValidators/passwordMatch';
import { setInStorage } from 'src/app/common/utils';
import { ForgotPassword, ForgotPasswordSubmit, SignIn } from 'src/app/services/auth/auth.service';

import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  langSelected = 'en';
  protected user: any;
  public forgotPasswordForm: FormGroup;
  public resetForgottenPasswordForm: FormGroup;
  action: string = 'step1';
  constructor(private formBuilder: FormBuilder, private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private managerService: ManagerService,) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.resetForgottenPasswordForm = this.formBuilder.group({
      code: ["", Validators.required],
      password: ["", [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],],
      cnfrmPassword: ["", [Validators.required]],
    },
      {
        validator: ConfirmedValidator("password", "cnfrmPassword"),
      });
  }
  resetForgottenPassword = async () => {
    let { code, password } = this.resetForgottenPasswordForm.value;
    this.spinner.show();
    let { message, result } = await ForgotPasswordSubmit(this.user, code, password);
    this.spinner.hide();
    if (!result) {
      this.toastr.error(message);
      if (message == ERROR_MESSAGES.INVALID_CODE) {
        this.action = "step2";
        this.resetForgottenPasswordForm.reset();
      }
      else
        this.router.navigate(['/login'])
      return;
    }
    if (this.langSelected == FRENCH_LANGUAGE)
      this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD_FR);
    else
      this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD);
    this.spinner.show();
    try {
      await SignIn(this.user, password);
      this.getManufacturer();
    } catch (err) {
      this.toastr.error(err.message);
    }
    this.spinner.hide();
  }

  getManufacturer = async () => {
    this.spinner.show();
    try {
      let result = await this.managerService.getUser();
      if (result && result.role && ALLOWED_ROLE.includes(result.role.id)) {
        setInStorage('user', result);
        if (this.langSelected == FRENCH_LANGUAGE)
          this.toastr.success(SUCCESS_MESSAGES.LOGIN_SUCCESSFUL_FR);
        else
          this.toastr.success(SUCCESS_MESSAGES.LOGIN_SUCCESSFUL);
        this.router.navigate(["/manager/dashboard"]);
        return;
      }
      this.toastr.error('Not Authorized.')
    } catch (err) {
      this.toastr.error(err.message);
    }
    this.spinner.hide();
  }
  forgotMyPassword = async () => {
    let { email } = this.forgotPasswordForm.value;
    this.spinner.show();
    let { message, result } = await ForgotPassword(email.toLowerCase());
    this.spinner.hide();
    this.user = email.toLowerCase();

    if (!result) {
      this.toastr.error(message);
      return;
    }
    if (this.langSelected == FRENCH_LANGUAGE)
      this.toastr.success(SUCCESS_MESSAGES.CODE_SENT_FR);
    else
      this.toastr.success(SUCCESS_MESSAGES.CODE_SENT);
    this.action = 'step2';

  }
  get formControl() {
    if (this.action == 'step1')
      return this.forgotPasswordForm.controls;
    else
      return this.resetForgottenPasswordForm.controls;
  }

  languageSelected(ln: string) {
    if (ln === DEFAULT_LANGUAGE_TYPE)
      this.langSelected = DEFAULT_LANGUAGE_TYPE;
    if (ln == FRENCH_LANGUAGE)
      this.langSelected = FRENCH_LANGUAGE
  }
}
