<!-- <div class="row">
  <div class="col-sm-5">
    <h2 class=" text-dark font-weight-bold">Contracts</h2>
  </div>

</div>
<br>
<br>
<div>
  <div>
    <div class="table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed table-hub">
        <thead>
          <tr>
            <th scope="col" sortable="title" (sort)="onSort($event)">Contracts</th>
            <th scope="col">Signature date</th>
            <th scope="col">Signed</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!contracts || contracts?.length === 0" class="text-center">
            <td colspan="4">No Contracts</td>
          </tr>
          <tr *ngFor="let contract of contracts; let i = index">

            <td class="cursor-pointer">{{contract?.contract_title}}</td>
            <td>{{contract?.signature_date | date}}</td>
            <td>
              <i class="fas fa-check" *ngIf="contract?.signature_date"></i>
              <i class="fas fa-times" *ngIf="!contract?.signature_date"></i>
            </td>
            <td class="cursor-pointer ">
              <a [routerLink]="[ contract?.id]">
                <i class="fas fa-eye"></i>
              </a>
              <a class="ml-2" (click)="download(contract)">
                <i class="fas fa-download"></i>
              </a>

            </td>

          </tr>

        </tbody>
      </table>
      <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" [currentPage]="page"
        (pageClicked)="setPage($event)" *ngIf="totalPage > pageCount">
      </app-pagination>
    </div>
  </div>
</div> -->

<div class="row">
  <div class="col-sm-5">
    <h2 class="text-dark font-weight-bold">Contracts</h2>
  </div>
</div>
<br />
<br />
<div>
  <div>
    <div class="table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed table-hub">
        <thead>
          <tr>
            <th scope="col" class="border-bottom-0">Date</th>
            <th scope="col" class="border-bottom-0 border-left-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngIf="!contractDocuments || contractDocuments?.length === 0"
            class="text-center"
          >
            <td colspan="4">No Contracts</td>
          </tr>
          <tr *ngFor="let contract of contractDocuments; let i = index">
            <td [ngClass]="i !== 0 ? 'border-top-0' : ''">
              {{ contract?.created_at | date : "yyyy-MM-dd" }}
            </td>
            <td
              [ngClass]="
                i !== 0 ? 'border-top-0 border-left-0' : 'border-left-0'
              "
            >
              <a
                [href]="contract.contract_url"
                target="_blank"
                class="btn btn-primary outline-none shadow-none border-0"
                >See contract</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
