import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NgcCookieConsentConfig } from "ngx-cookieconsent";
export const SUCCESS_MESSAGES = {
  LOGIN_SUCCESSFUL: "Login Successful !",
  LOGIN_SUCCESSFUL_FR: "Connexion réussie !",
  RESET_PASSWORD_FR: "Votre mot de passe a été réinitialisé avec succès.",
  RESET_PASSWORD: "Your password has been reset successfully.",
  PRODUCT_ADDED: "Product added successfully",
  PRODUCT_UPDATED: "Product updated successfully",
  API_DELETED: "API Bearer token deleted successfully",
  API_ADDED: "API Bearer token added successfully",
  API_UPDATED: "API Bearer token updated successfully",
  TOKEN_COPIED: "API token copied to clipboard",
  TOKEN_COPIED_ERROR: "Error in copying API token to clipboard",
  USER_CREATED: "User created successfully.",
  USER_DELETED: "User deleted successfully",
  USER_UPDATED: "User updated successfully",
  REGIONAL_DATA_UPDATED: "Regional Data updated successfully",
  MANU_UPDATED: "Manufacturer updated successfully",
  LANGUAGE_SWITCHED: "Language has been switched to %s successfully",
  CODE_SENT: "An email with a verification code was just sent to",
  CODE_SENT_FR: "Un e-mail avec un code de vérification vient dêtre envoyé à",
  SIGNUP_SUCCESS: "You have Successfully Registered",
  SIGNUP_PENDING:
    "Your registration is pending approval. Once approved you can perform this action",
  SIGNUP_PENDING_FR:
    "Votre inscription est en attente d`approbation. Une fois approuvé, vous pouvez effectuer cette action",
};

export const ERROR_MESSAGES = {
  UNKNOWN_SERVER_ERR: "Something went wrong !",
  CHANGE_PASSWORD: "This password is temporary. Please change your password.",
  CHANGE_PASSWORD_FR:
    "Ce mot de passe est temporaire. Veuillez changer votre mot de passe.",

  LANGUAGE_SWITCH: "Language could not be switched. Something went wrong.",
  INVALID_CODE: "Invalid verification code provided, please try again.",
  INVALID_CODE_FR:
    "Code de vérification fourni non valide, veuillez réessayer.",
};

export const VALIDATION_REGEX = {
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
  EAN: /^([a-zA-Z0-9_-]){13}$/,
  WEBSITE:
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  CONTACT_NUMBER: /^\+?([0-9]){6,14}[0-9]$/,
};

export const URLS = {
  GET_USER: "/user",
  GET_PRODUCTS: "/users/products",
  GET_METADATA: "/metadata/%s",
  UPDATE_PRODUCT: "/product",
  UPDATE_FILE: "/file",
  GET_PRODUCT_BY_ID: "/product/%s",
  PUBLC_PRODUCTS: "/products",
  DELETE_USER_BY_ID: "/user/%s",
  USERS: "/users",
  USER: "/user",
  GET_USER_BY_ID: "/user/%s",
  GET_REPORTS: "/reports",
  GET_CONTRACTS: "/contract",
  CREATE_CONTRACT: "/contract",
  CONTRACT_BY_ID: "/contract/%s",
  GET_APIS: "/tokens",
  DELETE_API_BY_ID: "/token/%s",
  POST_APIS: "/token",
  GET_REPORTS_EAN: "/reports-ean",
  GET_REPORTS_NEW: "/reports-new",
  MANUFACTURER_CONTRACTS: "/manufacturer/contracts",
  MANUFACTURER_BILLING_HISTORY: "/manufacturer/billing-history",
};

export const todayDate = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate(),
};

export const SOURCE = {
  WEB: "web",
};

export const FILE_TYPE = {
  image: 2,
  video: 1,
  driver: 3,
  document: 4,
};

export const ROLE_TYPE = {
  root: 1,
  superadmin: 4,
  consumer: 5,
};

export const ALLOWED_ROLE = [1, 2, 3];

export const LANGUAGE_TYPE = [
  {
    key: "ch",
    value: "Chinese",
  },
  {
    key: "en",
    value: "English",
  },
  {
    key: "fr",
    value: "French",
  },

  {
    key: "gr",
    value: "German",
  },
  {
    key: "hi",
    value: "Hindi",
  },
  {
    key: "ja",
    value: "Japanese",
  },
  {
    key: "po",
    value: "Portuguese",
  },
  {
    key: "ru",
    value: "Russian",
  },
  {
    key: "sp",
    value: "Spanish",
  },
];

export const ROLE_NAME = "root";

export const DEFAULT_LANGUAGE_TYPE = "en";
export const FRENCH_LANGUAGE = "fr";
export const MAX_FILE_SIZE = 104857600;
export const PAGE_COUNT = 10;
export const LIBRARY_COUNT = 100;
export const PLATFORM = "HUB";
export const COUNTRY = "country";
export const LANGUAGE = "language";

export const EDITOR_CONFIG: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: "auto",
  minHeight: "150px",
  maxHeight: "auto",
  width: "auto",
  minWidth: "0",
  translate: "yes",
  enableToolbar: true,
  showToolbar: true,
  placeholder: "Enter text here...",
  defaultParagraphSeparator: "",
  defaultFontName: "",
  defaultFontSize: "",
  fonts: [
    { class: "arial", name: "Arial" },
    { class: "times-new-roman", name: "Times New Roman" },
    { class: "calibri", name: "Calibri" },
    { class: "comic-sans-ms", name: "Comic Sans MS" },
  ],
  sanitize: true,
  toolbarPosition: "top",
  toolbarHiddenButtons: [],
};

export const COOKIE_CONFIG: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: "top-left",
  theme: "classic",
  palette: {
    popup: {
      background: "#000000",
      text: "#ffffff",
      link: "#ffffff",
    },
    button: {
      background: "#f1d600",
      text: "#000000",
      border: "transparent",
    },
  },
  type: "opt-out",
  content: {
    message:
      "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Decline",
    allow: "Accept cookies",
    link: "",
    href: "",
    policy: "Cookie Policy",
  },
};

export enum PlanType {
  Professional = "Professional",
  Discovery = "Discovery",
}
