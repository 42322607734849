<!-- Start Page content holder -->
<div class="" id="content">
  <div class="Main-hesd">
    <h2 class="text-dark font-weight-bold">{{ 'dashboard' | translate }}</h2>
  </div>

  <div class="chart-cri">
    <div class="row">
      <div class="col-md-4" *ngFor="let chart of pieChartsData,let i=index">
        <div class="chart-box text-alignment">
          <h2 translate>{{pieChartsHeadings[i]}}</h2>
          <h6 translate>{{pieChartsTitles[i]}}</h6>
          <div>
            <canvas baseChart [data]="chart" [type]="pieChartType"
              [labels]="chart.status ? chart.labels : ['No Data', 'No Data', 'No Data', 'No Data', 'No Data', 'No Data', 'No Data', 'No Data', 'No Data', 'No Data', 'No Data', 'No Data']"
              [options]="pieChartOptions" [plugins]="pieChartPlugins">
            </canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let chart of lineChartsConfig; let i=index">
      <div class="col-md-12">
        <div class="chart-box">
          <h3 translate>{{titles[i]}}</h3>
          <div class="chart_div">
            <canvas baseChart id="myChart_demo1" [datasets]="chart?.datasets" [labels]="chart?.labels"
              [options]="lineChartOptions"
              [type]="lineChartType">
            </canvas>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>