<div class="main-produ mt-4 mb-5 d-inline-block w-100">
  <h2 class="font-weight-bold text-dark">{{ "manufacturer" | translate }}</h2>
</div>
<h5 class="Reg-heading font-weight-bold pl-4 mb-4 text-dark">
  {{ "description" | translate }}
</h5>
<div class="Reg-part border border-light rounded">
  <form
    class="form-horizontal d-inline-block w-100 px-4 pt-4"
    role="form"
    [formGroup]="detailsForm"
  >
    <div class="form-group mb-0">
      <div class="row">
        <label
          for="manufacturer_name"
          class="col-lg-2 text-dark col-form-label mt-0 mt-md-3"
          >{{ "name" | translate }}</label
        >

        <div class="col-lg-7">
          <input
            type="text"
            formControlName="manufacturer_name"
            class="form-control border border-light text-dark ng-untouched ng-pristine ng-valid"
            [readonly]="user"
            [ngClass]="{ 'disble-box': user }"
            id="manufacturer_name"
            placeholder="{{ 'enter' | translate }} {{ 'name' | translate }}"
          />
        </div>

        <div class="film-part col-lg-3">
          <div
            class="film-box mt-4 mt-lg-0 border border-light rounded"
            (click)="addImage.click()"
          >
            <div
              class="img-pt border border-light position-relative d-inline-block rounded h-100"
              *ngIf="photoURL"
            >
              <img [src]="photoURL" class="img-wrapper" />
            </div>

            <input
              #addImage
              hidden
              type="file"
              name="file[]"
              placeholder="{{ 'add' | translate }} image"
              accept="image/*"
              id="addImage"
              (change)="selectFile($event, 'image')"
            />
          </div>
          <button
            class="btn btn-primary w-140 mt-1"
            (click)="
              photoURL ? (photoURL = null && (photo = null)) : addImage.click()
            "
            *ngIf="photoURL"
          >
            {{ "remove Image" | translate }}
          </button>
          <button
            class="btn btn-primary w-140 mt-1"
            (click)="
              photoURL ? (photoURL = null && (photo = null)) : addImage.click()
            "
            *ngIf="!photoURL"
          >
            {{ "add Image" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-lg-2 text-dark mt-0 mt-md-3 h-100 pt-1">{{
        "description" | translate
      }}</label>
      <div class="col-lg-10 d-flex mt-2">
        <textarea
          class="form-control desc-heig border border-light text-dark h-100 p-2"
          rows="4"
          placeholder="{{ 'enter' | translate }} {{
            'description' | translate
          }}"
          formControlName="description"
        ></textarea>
      </div>
    </div>
    <!-- <div class="form-group row">
      <label class="col-lg-2 text-dark mt-0 mt-md-3  h-100 pt-1">{{'html description'| translate }}</label>
      <div class="col-lg-10 d-flex mt-2">
        <angular-editor  [config]="editorConfig" formControlName="html_description"></angular-editor>
      </div>
    </div> -->
  </form>
  <form
    class="form-horizontal d-inline-block w-100 px-4 pt-2 pb-4"
    role="form"
    [formGroup]="rootForm"
  >
    <div class="form-group row mt-0">
      <label
        for="root_ean"
        class="col-lg-2 text-dark control-label mt-0 mt-md-3"
        >Root EAN</label
      >
      <div class="col-lg-9 d-flex">
        <input
          type="text"
          id="root_ean"
          placeholder="Enter Root EAN "
          class="form-control border border-light col-sm-9 d-inline-block disble-box text-dark"
          formControlName="root_ean"
          readonly
        />

        <img
          ngbPopover="Root EAN"
          triggers="mouseenter:mouseleave"
          src="../../../assets/images/q-mark.png"
          class="q-mark ml-4 mt-3"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-lg-2 text-dark control-label mt-0 mt-md-3"
        >Root {{ "account" | translate }}</label
      >
      <div class="col-lg-9 d-flex">
        <input
          type="email"
          id="email"
          placeholder="{{ 'enter' | translate }} Root Account"
          class="form-control border border-light col-sm-9 d-inline-block disble-box text-dark"
          formControlName="email"
          readonly
        />
        <img
          ngbPopover="Root {{ 'account' | translate }}"
          triggers="mouseenter:mouseleave"
          src="../../../assets/images/q-mark.png"
          class="q-mark ml-4 mt-3"
        />
      </div>
    </div>
    <div class="form-group row">
      <label
        for="contact_number"
        class="col-lg-2 text-dark control-label mt-0 mt-md-3"
      >
        Root {{ "phone number" | translate }}</label
      >
      <div class="col-lg-9 d-flex">
        <input
          type="text"
          id="contact_number"
          placeholder="{{ 'enter' | translate }} Root {{
            'phone number' | translate
          }} (+1234567890)"
          class="form-control col-sm-9 d-inline-block border border-light col-sm-9 d-inline-block disble-box text-dark"
          readonly
          formControlName="contact_number"
        />
        <img
          ngbPopover="Root {{ 'phone number' | translate }}"
          triggers="mouseenter:mouseleave"
          src="../../../assets/images/q-mark.png"
          class="q-mark ml-4 mt-3"
        />
      </div>
    </div>

    <div class="d-flex justify-content-end form-group row mb-0">
      <!-- <button type="button" class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
        [routerLink]="['/manager/manufacturers']">Cancel</button> -->
      <button
        type="button"
        class="btn button_form rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3 mb-0"
        [disabled]="
          this.user ? !detailsForm.valid : !detailsForm.valid || !rootForm.valid
        "
        (click)="updateUser()"
      >
        {{ "save" | translate }}
      </button>
    </div>
  </form>
</div>
<h5 class="Reg-heading font-weight-bold pl-4 mt-4 text-dark">
  {{ "regionalized manufacturer data" | translate }}
</h5>
<div class="Reg-part mt-3 border border-light rounded">
  <form class="form-horizontal d-inline-block w-100 p-4" role="form">
    <br />
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead class="text-dark">
          <tr>
            <th scope="col">{{ "icon" | translate }}</th>
            <th scope="col">{{ "language" | translate }}</th>
            <th scope="col">{{ "country" | translate }}</th>
            <th scope="col">{{ "description" | translate }}</th>
            <th scope="col">{{ "html description" | translate }}</th>
            <th scope="col">{{ "option" | translate }}</th>
          </tr>
        </thead>
        <tbody class="text-dark">
          <tr *ngFor="let data of regionalData; let i = index">
            <td>
              <a class="edit-pen pl-3"
                ><img
                  *ngIf="data.photo_url"
                  class="regional-icon"
                  [src]="data.photo_url"
              /></a>
            </td>
            <td>{{ metadataName("languages", data.language_id) }}</td>
            <td>{{ metadataName("countries", data.country_id) }}</td>
            <td>{{ data.description }}</td>
            <td><div [innerHTML]="data.html_description"></div></td>
            <td>
              <a class="edit-pen pl-3"
                ><img
                  src="../../../assets/images/edit-icon.png"
                  (click)="addRegionalData(i)"
              /></a>
              <a class="remove pl-3"
                ><img
                  src="../../../assets/images/delete-icon.png"
                  (click)="deleteRegionalData(i)"
              /></a>
            </td>
          </tr>

          <tr
            *ngIf="!regionalData || (regionalData && regionalData.length === 0)"
          >
            <td class="border-0">
              {{ "no" | translate }} {{ "regional data" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end form-group row mb-0">
      <button
        type="button"
        class="btn button_form rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        (click)="addRegionalData()"
      >
        {{ "add" | translate }}
      </button>
      <button
        type="button"
        class="btn button_form rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="
          user
            ? user?.regional_data?.length === 0 && regionalData.length === 0
            : true
        "
        (click)="updateUser('regional')"
      >
        {{ "save" | translate }}
      </button>
    </div>
  </form>
</div>
