import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SUCCESS_MESSAGES, ERROR_MESSAGES, PlanType } from 'src/app/common/constants';
import { getFromStorage, removeNullOrEmpty } from 'src/app/common/utils';
import { API } from 'src/app/models/apis';
import { AddApi, deleteApiById, getApi } from 'src/app/services/manager.service';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'app-apis',
  templateUrl: './apis.component.html',
  styleUrls: ['./apis.component.scss']
})
export class ApisComponent implements OnInit {

  public apis: API[];
  public addApi: boolean = false;
  public detailsForm: FormGroup;
  private isEdit: boolean = false;
  private api: API;
  user!:any;
  planType=PlanType
  constructor(private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getApiList();
    this.detailsForm = this.formBuilder.group({
      comments: [""],
    });

    this.user=getFromStorage("user")
  }

  getApiList = async () => {
    this.spinner.show();
    try{
    this.apis = await getApi();
    }catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  deleteToken = async (id) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message = 'Are you sure you want to delete API token ?';
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      console.log("comp closed", result);
      if (result) {
        this.handleDeleteToken(id);
      }
    });
  }

  handleDeleteToken = async (id) => {
    this.spinner.show();
    try {
      let { success } = await deleteApiById(id);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.API_DELETED);
        this.getApiList();
      }
    } catch (err) {
      this.toastr.error(err.message);
    }finally{
      this.spinner.hide();
    }
  }


  addToken = async () => {
    let data = this.detailsForm.value;
    data["manufacturer_id"] =
    this.user.users_manufacturers[0].manufacturer_id;;
    data = removeNullOrEmpty(data);
    if (this.isEdit) data.id = this.api.id;
    this.spinner.show();
    try {
      let { success } = await AddApi(data);
      if (success) {
        let message = this.isEdit ? SUCCESS_MESSAGES.API_UPDATED : SUCCESS_MESSAGES.API_ADDED;
        this.toastr.success(message);
        this.addApi = false;
        this.detailsForm.reset();
        this.getApiList();
      }
    } catch (err) {
      this.toastr.error(err.message);
    }finally{
      this.spinner.hide();
    }
  }

  editToken = (token) => {
    this.api = token;
    this.addApi = true;
    this.isEdit = true;
    const fieldsToPatch = ['comments'];
    for (let field of fieldsToPatch) {
      let patch = {};
      patch[field] = token[field];
      this.detailsForm.patchValue(patch);
    }
  }

  copyToken = (token) => {
    const textArea = document.createElement("textarea");
    try {
      textArea.value = token;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      this.toastr.info(SUCCESS_MESSAGES.TOKEN_COPIED);
    } catch (err) {
      this.toastr.error(SUCCESS_MESSAGES.TOKEN_COPIED_ERROR);
    } finally {
      document.body.removeChild(textArea);
    }

  }

}
