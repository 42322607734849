import { Routes } from "@angular/router";
import { ApisComponent } from "src/app/pages/apis/apis.component";
import { ContractsComponent } from "src/app/pages/contracts/contracts.component";
import { ManufacturerComponent } from "src/app/pages/manufacturer/manufacturer.component";
import { UsersComponent } from "src/app/pages/users/users.component";
import { ViewContractComponent } from "src/app/pages/view-contract/view-contract.component";
import { DashboardComponent } from "../../pages/dashboard/dashboard.component";
import { ViewProductsComponent } from "../../pages/view-products/view-products.component";
import { PlanComponent } from "src/app/pages/plan/plan.component";

export const ManagerLayoutRoutes: Routes = [
  // { path: "", component: DashboardComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "products", component: ViewProductsComponent },
  { path: "products/:id", component: ViewProductsComponent },
  { path: "users", component: UsersComponent },
  { path: "plan", component: PlanComponent },
  { path: "manufacturer", component: ManufacturerComponent },
  { path: "api", component: ApisComponent },
  { path: "contracts", component: ContractsComponent },
  { path: "contracts/:id", component: ViewContractComponent },
  {
    path: "billing",
    loadChildren: () =>
      import("../../pages/billing/billing.module").then((m) => m.BillingModule),
  },
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
];
