import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ROLE_NAME, SUCCESS_MESSAGES, VALIDATION_REGEX, DEFAULT_LANGUAGE_TYPE, FRENCH_LANGUAGE , ROLE_TYPE } from 'src/app/common/constants';
import { removeNullOrEmpty } from 'src/app/common/utils';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  langSelected = 'en';
  isMutePass: boolean = false;
  public signUpForm: FormGroup;
  action: string;
  user: any;
  constructor(private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private managerService: ManagerService,) { }

  ngOnInit(): void {
    this.signUpForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      manufacturer_name: ['', Validators.required],
      url: ['', [Validators.required,Validators.pattern(VALIDATION_REGEX.WEBSITE)]],
      email: ["", [Validators.required, Validators.email]],
      contact_number: ['', Validators.pattern(VALIDATION_REGEX.CONTACT_NUMBER)]
    });
  }


  signUpNewUser = async () => {
    let data = { ...removeNullOrEmpty(this.signUpForm.value) };
    data['role_id'] = ROLE_TYPE.root; 
    data.email = data.email ? data.email.toLowerCase() : '';
    data.country_id = 2;
    this.spinner.show();
    this.managerService.signup(data).subscribe((data)=>{
        this.spinner.hide();
        if (this.langSelected == FRENCH_LANGUAGE){
          this.toastr.success(SUCCESS_MESSAGES.SIGNUP_PENDING_FR);
        }
        else{
          this.toastr.success(SUCCESS_MESSAGES.SIGNUP_PENDING);
        }
        this.user = data
        this.router.navigate(["/account-activation"]);
    }, error => {
        this.spinner.hide();
        this.toastr.error(error.message);
        this.action = "login"
    })
  }
  get formControl() {
    return this.signUpForm.controls;
  }

  languageSelected(ln: string) {
    if (ln === DEFAULT_LANGUAGE_TYPE)
      this.langSelected = DEFAULT_LANGUAGE_TYPE;
    if (ln == FRENCH_LANGUAGE)
      this.langSelected = FRENCH_LANGUAGE
  }

}
